// ChatButton.js
import React, { useState, useEffect, useRef } from "react";
import "./ChatButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ChipList from "../../fragments/chips/ChipList";
import Chip from "../../fragments/chips/Chip";
import { useLocation } from "react-router-dom";
import { sendMessageToChatbot } from "../../../../api/chatbot/PsyGenieChatBot";
import traitColors from "../../../../traits";

const ChatButton = ({ people }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [messages, setMessages] = useState([]);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
      setSelectedPerson(null); // Reset selected person when closing chat
    }

    return () => {
      document.body.classList.remove("no-scroll"); // Cleanup when component unmounts
    };
  }, [isOpen]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]); // Triggered whenever the messages array changes

  const location = useLocation();
  if (
    location.pathname === "/login" ||
    location.pathname === "/login/" ||
    location.pathname === "/change-password" ||
    location.pathname === "/change-password/" ||
    location.pathname === "/signup" ||
    location.pathname === "/signup/" ||
    location.pathname === "/"
  ) {
    return null;
  }

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handlePersonClick = (person) => {
    setSelectedPerson(person);
    const storedMessages = localStorage.getItem(`chat_${person.id}`);
    setMessages(storedMessages ? JSON.parse(storedMessages) : []);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.elements.messageInput.value.trim();
    if (message) {
      const newMessages = [...messages, { text: message, sender: "me" }];
      setMessages(newMessages);
      localStorage.setItem(
        `chat_${selectedPerson.id}`,
        JSON.stringify(newMessages),
      );

      // Make the API request and get the response
      const aiResponse = await sendMessageToChatbot(selectedPerson, message);
      if (aiResponse) {
        const updatedMessages = [
          ...newMessages,
          { text: aiResponse, sender: "ai" },
        ];
        setMessages(updatedMessages);
        localStorage.setItem(
          `chat_${selectedPerson.id}`,
          JSON.stringify(updatedMessages),
        );
      }

      e.target.reset();
    }
  };

  const handleBackClick = () => {
    setSelectedPerson(null);
  };

  return (
    <div className="chat-button-container">
      <button className="chat-button" onClick={toggleChat}>
        <FontAwesomeIcon icon={faComments} className="chat-button-icon" />
      </button>

      {isOpen && (
        <>
          <div className="chat-backdrop" onClick={toggleChat}></div>
          <div className="chat-window">
            {!selectedPerson ? (
              <>
                <h3>Ask PsyGenie</h3>
                <p>Select a person to ask PsyGenie about:</p>
                <ul>
                  {people.map((person) => (
                    <li
                      key={person.id}
                      onClick={() => handlePersonClick(person)}
                    >
                      <img
                        src={person.profilePic}
                        alt={person.name}
                        className="chat-window-profile-pic"
                      />
                      {person.name}s
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <div className="chat-header">
                  <button className="back-button" onClick={handleBackClick}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </button>
                  <img
                    src={selectedPerson.profilePic}
                    alt={selectedPerson.name}
                    className="profile-pic-large"
                  />
                  <span>{selectedPerson.name}</span>
                </div>
                <div className="chat-secondary-header">
                  <ChipList singleLine={true}>
                    {selectedPerson.personality.map((ocean) => (
                      <Chip
                        text={ocean.trait}
                        circleText={ocean.percentage + "%"}
                        circleColor={traitColors[ocean.trait]}
                      />
                    ))}
                  </ChipList>
                  <ChipList singleLine={true}>
                    {selectedPerson.emotions.map((emotion) => (
                      <Chip
                        text={emotion.emotion}
                        circleText={emotion.percentage + "%"}
                      />
                    ))}
                  </ChipList>
                </div>
                <div className="chat-messages">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      ref={
                        index === messages.length - 1 ? lastMessageRef : null
                      }
                      className={`chat-bubble ${message.sender}`}
                    >
                      {message.text}
                    </div>
                  ))}
                </div>
                <form className="chat-input" onSubmit={handleSendMessage}>
                  <input
                    type="text"
                    name="messageInput"
                    placeholder="Type a message..."
                  />
                  <button type="submit">Send</button>
                </form>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ChatButton;
