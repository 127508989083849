.icon-with-badge {
    position: relative;
    display: inline-block;
}

.icon {
    font-size: 24px; /* Adjust size as needed */
}

.count_badge {
    position: absolute;
    color: white;
    font-size: 10px;
    top: 12px;
    font-weight: bold;
    padding: 3px 7px;
    background: red;
    border-radius: 50%;
}

.icon-li {
    background: cornflowerblue;
    padding: 5px 10px;
    border-radius: 30px;
}