import React, { useState } from 'react';

const SearchBox = ({ placeholder, data, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        const newTerm = event.target.value;
        setSearchTerm(newTerm);
        onSearch(newTerm); // Pass the search term back to the parent
    };

    return (
        <div>
            <input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleChange}
            />
        </div>
    );
};

export default SearchBox;