/* Action.module.css */

.actionContainer {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  padding: 20px;
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 4px solid transparent;
  border-image-slice: 1;
}

.cardTitle {
  font-size: 20px;
  margin-bottom: 15px;
  color: #494969; /* Dark color for the title */
  text-align: center;
}

.table {
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure equal spacing between items */
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.row:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cell {
  flex: 3; /* Increased flex to give more space to the message text */
  margin: 0;
  font-size: 14px;
}

.name {
  font-weight: bold;
  margin-bottom: 5px;
}

.details {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align tags and timestamp to the right */
  flex: 2; /* Adjust based on how much space you want for the tags and timestamp */
  gap: 8px;
  flex-wrap: wrap; /* Allows wrapping of elements if necessary */
}

.tag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
  margin-right: 8px;
}

.emailTag {
  border: rgba(255, 111, 97, 0.9);
}

.slackTag {
  background-color: rgba(121, 134, 203, 0.9);
}

.defaultTag {
  background-color: #999; /* Grey for any other source */
}

.emotionTag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
}

/* Emotion Colors */
.happyEmotion {
  background-color: #ff6f61;
}

.excitedEmotion {
  background-color: #4db6ac;
}

.neutralEmotion {
  background-color: #ffb74d;
}

.stressedEmotion {
  background-color: #7986cb;
}

.relaxedEmotion {
  background-color: #81c784;
}

.sourceTag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  background-color: #f5f5f5;
  color: #494969;
  margin-right: 8px;
}

.parent-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; /* Align items at the top */
}

/* Alternative: If you want more control using grid */
.parent-container {
  display: grid;
  justify-items: start; /* Aligns items to the start (left) */
  grid-template-columns: auto; /* Each card will take up as much space as it needs */
}

.redirectIcon {
  color: #4db6ac;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.redirectIcon:hover {
  color: #388e7b;
}

.timestamp {
  font-size: 12px;
  color: black;
  margin-left: 10px;
}

.viewMoreContainer {
  text-align: center;
  margin-top: 15px;
}

.viewMoreLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4db6ac;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.viewMoreLink:hover {
  background-color: #388e7b;
}
