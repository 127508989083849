.pageNotFound {
  text-align: center;
  padding: 50px;
}

.pageNotFound h1 {
  font-size: 48px;
  color: #333;
}

.pageNotFound p {
  font-size: 24px;
  color: #666;
}
