.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusts alignment of content */
  width: 100%;
  overflow: hidden; /* Prevents content overflow */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  padding: 0 15px; /* Adds padding to ensure content is not too close to the edges */
  background-color: white; /* Adjust based on design */
  border-bottom: 1px solid #ddd; /* Optional border */
  height: 70px; /* Adjust height as needed */
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 20px;
  margin-top: 10px;
}

.avatarAndText {
  display: flex;
  align-items: center;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns content to the left */
}

.selectedMenu {
  margin-left: 0;
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-left: 250px;
  white-space: nowrap;
}

ul,
ol {
  list-style-type: none; /* Removes the default marker */
  padding-left: 0; /* Removes the default padding */
}

li::marker {
  content: none; /* Ensures no marker is displayed */
}

.header {
  display: flex;
  /* width: 90%; */
  /* margin: 2% auto; */
  align-items: center;
  background: white;
  padding: 5px;
  /* top: 0px; */
}

.header-landing-navbar {
  background: #5965fd !important;
  position: fixed;
  width: 100%;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.avatarAndText {
  display: flex;
  align-items: center; /* Center items vertically */
}

.textWrapper {
  margin-left: 10px; /* Add spacing between avatar and text */
}

@media all and (max-width: 600px) {
  .header {
    margin: 2.5% auto;
  }
}
