// src/components/TestimonialsSection.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const TestimonialsSection = () => {
    return (
        <section id="testimonials" className="py-5 bg-light">
            <Container>
                <h2 className="text-center mb-4">What Our Users Say</h2>
                <Row>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Text>"PsyGenie has transformed my mental well-being. Highly recommend!"</Card.Text>
                                <Card.Footer className="text-muted">— Jane Doe</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Text>"The insights and progress tracking are fantastic. A must-have app."</Card.Text>
                                <Card.Footer className="text-muted">— John Smith</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Text>"Connecting with the community has been a game-changer for me."</Card.Text>
                                <Card.Footer className="text-muted">— Emily Johnson</Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default TestimonialsSection;