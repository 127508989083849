import React from 'react';
import styles from '../../../../css/TopMessages.module.css'; // Ensure this path is correct
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const topMessages = [
  { name: 'John Doe', emotion: 'happy', text: 'Sub: Lets go for party', source: 'email', link: '#', timestamp: '2024-08-24 10:00' },
  { name: 'Jane Smith', emotion: 'excited', text: 'Channel: Psygenie', source: 'slack', link: '#', timestamp: '2024-08-24 10:30' },
  { name: 'John Doe', emotion: 'neutral', text: 'Sub: Its was busy week', source: 'email', link: '#', timestamp: '2024-08-24 10:00' },
  { name: 'Jane Smith', emotion: 'stressed', text: 'Channel: Response', source: 'slack', link: '#', timestamp: '2024-08-24 10:30' },
  { name: 'John Doe', emotion: 'relaxed', text: 'Sub: Replace my laptop', source: 'email', link: '#', timestamp: '2024-08-24 10:00' },
];

const TopMessages = ({ messages = topMessages }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardTitle}>Top Messages</div>
      <div className={styles.table}>
        {messages.length === 0 ? (
          <p>No messages available.</p>
        ) : (
          messages.map((message, index) => (
            <div key={index} className={styles.row}>
              <span className={`${styles.emotionTag} ${styles[`${message.emotion.toLowerCase()}Emotion`]}`}>
                {message.name}
              </span>
              <span className={styles.sourceTag}>{message.timestamp}</span>
              <span className={`${styles.sourceTag} ${styles[`${message.source}Tag`]}`}>
                {message.source}
              </span>
              <span className={styles.cell}>{message.text}</span>
              <span className={`${styles.emotionTag} ${styles[`${message.emotion.toLowerCase()}Emotion`]}`}>
                {message.emotion}
              </span>
              <span
                className={styles.redirectIcon}
                title="Read more"
                onClick={() => window.open(message.link, '_blank', 'noopener noreferrer')}
              >
                <FaExternalLinkAlt />
              </span>
            </div>
          ))
        )}
      </div>
      <div className={styles.viewMoreContainer}>
        <Link to="/action" className={styles.viewMoreLink}>
          View More
        </Link>
      </div>
    </div>
  );
};

export default TopMessages;
