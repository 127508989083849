.chip_list {
  display: flex;
  flex-wrap: wrap;
}

.chip_list.horizontal {
  flex-direction: row;
}

.chip_list.vertical {
  flex-direction: column;
}

.chip_list.single_line.horizontal {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.chip_list.single_line.vertical {
  flex-wrap: nowrap;
  overflow-y: auto;
}

.chip_wrapper {
  margin: 5px;
}
