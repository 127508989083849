import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TopMessages from './TopMessages';
import styles from '../../../../css/ExpandedModal.module.css';

// Dummy data for different traits
const rows = {
  Openness: [
    { id: 1, title: 'Use of Imaginative and Abstract Language', details: 'Frequent use of metaphors, similes, and creative descriptions, indicating a preference for abstract thinking.' },
    { id: 2, title: 'Curiosity and Interest in Novelty', details: 'Mentioning new ideas, exploring diverse topics, or showing enthusiasm for learning and new experiences.' },
    { id: 3, title: 'Complex Vocabulary', details: 'Use of sophisticated and varied vocabulary, indicating intellectual engagement and a preference for complexity.' },
    { id: 4, title: 'Artistic and Cultural References', details: 'Frequent references to art, literature, music, or cultural experiences, showing an appreciation for aesthetics and creativity.' },
    { id: 5, title: 'Unconventional Perspectives', details: 'Offering unique or non-traditional viewpoints, indicating a tendency to think outside the box.' },
  ],
  Conscientiousness: [
    { id: 6, title: 'Organized and Structured Writing', details: 'Clear, well-organized text with logical progression, indicating a disciplined and methodical approach.' },
    { id: 7, title: 'Attention to Detail', details: 'Careful attention to spelling, grammar, punctuation, and precision in expression, reflecting thoroughness and diligence.' },
    { id: 8, title: 'Goal-Oriented Language', details: 'Frequent references to goals, plans, tasks, or achievements, indicating a focus on productivity and success.' },
    { id: 9, title: 'Responsibility and Reliability', details: 'Mentioning commitments, deadlines, or obligations, reflecting a sense of duty and reliability.' },
    { id: 10, title: 'Cautious and Deliberate Tone', details: 'A careful and measured writing style, avoiding impulsive or spontaneous expressions, indicating a tendency toward planning and caution.' },
  ],
    Extraversion: [
      { id: 11, title: 'Frequent Social References', details: 'Mentioning social interactions, group activities, or relationships, indicating a preference for social engagement and connection.' },
      { id: 12, title: 'Positive and Energetic Language', details: 'Use of enthusiastic, assertive, and energetic expressions, reflecting a lively and outgoing personality.' },
      { id: 13, title: 'Talkative and Expressive Style', details: ' A tendency to write more extensively, with a conversational tone, indicating comfort with verbal expression and sociability' },
      { id: 14, title: 'Use of Emotive Language', details: 'Frequent use of words expressing emotions such as excitement, joy, or enthusiasm, indicating a tendency to be emotionally expressive.' },
      { id: 15, title: 'Leadership and Influence References', details: 'Mentioning roles in leading, influencing, or motivating others, reflecting a tendency toward assertiveness and social dominance.' },
    ],
  Agreeableness: [
    { id: 16, title: 'Empathetic and Supportive Language', details: 'Expressions of understanding, compassion, and concern for others, indicating a warm and caring personality.' },
    { id: 17, title: 'Cooperative and Harmonious Tone', details: 'A tendency to avoid conflict, using language that seeks consensus, compromise, or agreement, reflecting a preference for harmony.' },
    { id: 18, title: 'Politeness and Consideration', details: 'Frequent use of courteous expressions, such as “please” and “thank you,” indicating a respectful and considerate attitude.' },
    { id: 19, title: 'Altruistic and Helping References', details: 'Mentioning acts of kindness, assistance, or support for others, reflecting a selfless and cooperative nature.' },
    { id: 20, title: 'Positive Descriptions of Others', details: 'Speaking well of others, avoiding criticism or negativity, indicating a tendency to see the good in people.' },
  ],
    Neuroticism: [
      { id: 21, title: 'Frequent Mention of Anxiety or Worry', details: 'Discussing fears, concerns, or stress, indicating a tendency toward emotional instability and anxiety.' },
      { id: 22, title: 'Mood Fluctuations in Tone', details: 'Sudden changes in emotional tone within the writing, reflecting mood swings or emotional sensitivity.' },
      { id: 23, title: 'Negative Self-Perception', details: 'Self-critical or pessimistic statements, indicating low self-esteem or insecurity.' },
      { id: 24, title: 'Focus on Problems or Challenges', details: 'Emphasizing difficulties, setbacks, or obstacles, indicating a tendency to dwell on negative aspects.' },
      { id: 25, title: 'Expressions of Sadness or Anger', details: 'Use of words indicating frustration, sadness, or irritability, reflecting a tendency toward emotional distress.' },
    ]
};

const ExpandedModal = ({ traitKey, expandedRow, onRowClick }) => {
  const [expandedRowState, setExpandedRowState] = useState(expandedRow);

  const handleRowClick = (rowId) => {
    setExpandedRowState(expandedRowState === rowId ? null : rowId);
    onRowClick(rowId); // Call the parent handler
  };

  const filteredRows = rows[traitKey] || [];

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>Overview</div>
      <div className={styles.parentCard}>
        {filteredRows.map((row) => (
          <div
            key={row.id}
            className={`${styles.row} ${expandedRowState === row.id ? styles.expanded : ''}`}
            onClick={() => handleRowClick(row.id)}
          >
            <div className={styles.rowTitle}>{row.title}</div>
            {expandedRowState === row.id && (
              <div className={styles.rowDetails}>
                {row.details}
                <TopMessages />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

ExpandedModal.propTypes = {
  traitKey: PropTypes.string.isRequired, // Ensure this is a required string
  expandedRow: PropTypes.number,
  onRowClick: PropTypes.func.isRequired,
};

export default ExpandedModal;
