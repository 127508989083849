import React from 'react';
import styles from '../../../../css/Action.module.css'; // Ensure this path is correct
import { FaExternalLinkAlt } from 'react-icons/fa';

const Action = () => {
  // Updated data with emotions for top people
  const topMessages = [
    { name: 'John Doe', text: 'Sub: Lets go for party', source: 'email', emotion: 'Happy', link: '#', timestamp: '2024-08-24 10:00' },
    { name: 'Jane Smith', text: 'Channel: Psygenie', source: 'slack', emotion: 'Excited', link: '#', timestamp: '2024-08-24 10:30' },
    { name: 'John Doe', text: 'Sub: Its was busy week', source: 'email', emotion: 'Stressed', link: '#', timestamp: '2024-08-24 10:00' },
    { name: 'Jane Smith', text: 'Channel: Response', source: 'slack', emotion: 'Neutral', link: '#', timestamp: '2024-08-24 10:30' },
        { name: 'John Doe', text: 'Sub: Lets go for party', source: 'email', emotion: 'Happy', link: '#', timestamp: '2024-08-24 10:00' },
        { name: 'Jane Smith', text: 'Channel: Psygenie', source: 'slack', emotion: 'Excited', link: '#', timestamp: '2024-08-24 10:30' },
        { name: 'John Doe', text: 'Sub: Its was busy week', source: 'email', emotion: 'Stressed', link: '#', timestamp: '2024-08-24 10:00' },
        { name: 'Jane Smith', text: 'Channel: Response', source: 'slack', emotion: 'Neutral', link: '#', timestamp: '2024-08-24 10:30' },
    // Add more messages as needed
  ];

  const topPeople = [
    { name: 'John Doe', email: 'john.doe@example.com', source: 'email', emotion: 'Happy' },
    { name: 'Jane Smith', email: 'jane.smith@example.com', source: 'slack', emotion: 'Stressed' },
        { name: 'John Doe', email: 'john.doe@example.com', source: 'email', emotion: 'Happy' },
        { name: 'Jane Smith', email: 'jane.smith@example.com', source: 'slack', emotion: 'Stressed' },
    // Add more people as needed
  ];

  return (
    <div className="main-content-div">
      <div className={styles.actionContainer}>
        {/* Top Messages Card */}
        <div className={styles.card}>
          <h2 className={styles.cardTitle}>Top Messages</h2>
          <div className={styles.table}>
            {topMessages.length === 0 ? (
              <p>No messages available.</p>
            ) : (
          topMessages.map((message, index) => (
            <div key={index} className={styles.row}>
                <span className={`${styles.emotionTag} ${styles[`${message.emotion.toLowerCase()}Emotion`]}`}>{message.name}</span>
                <span className={styles.sourceTag}>{message.timestamp}</span>
                <span className={`${styles.sourceTag} ${styles[`${message.source}Tag`]}`}>
                  {message.source}
                </span>
              <span className={styles.cell}>{message.text}</span>
                <span className={`${styles.emotionTag} ${styles[`${message.emotion.toLowerCase()}Emotion`]}`}>
                  {message.emotion}
                </span>
                <span
                  className={styles.redirectIcon}
                  title="Read more"
                  onClick={() => window.open(message.link, '_blank', 'noopener noreferrer')}
                >
                  <FaExternalLinkAlt />
                </span>
              </div>
          ))
            )}
          </div>
        </div>

        {/* Top People Card */}
        <div className={styles.card}>
          <h2 className={styles.cardTitle}>Top People</h2>
          <div className={styles.table}>
            {topPeople.length === 0 ? (
              <p>No people available.</p>
            ) : (
              topPeople.map((person, index) => (
                <div key={index} className={styles.row}>
                  <div className={styles.cell}>
                    <span className={`${styles.emotionTag} ${styles[`${person.emotion.toLowerCase()}Emotion`]}`}>{person.name}</span>
                    <span className={styles.sourceTag}>{person.email}</span>
                  </div>
                  <span className={`${styles.sourceTag} ${styles[`${person.source}Tag`]}`}>
                    {person.source}
                  </span>
                  <span className={`${styles.emotionTag} ${styles[`${person.emotion.toLowerCase()}Emotion`]}`}>
                    {person.emotion}
                  </span>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;
