import React, { useState } from "react";
import PropTypes from "prop-types";
import TopMessages from "./TopMessages";
import styles from "../../../../css/Training.module.css";
import ComingSoon from "./ComingSoon";
import TacticsOfDay from "./TacticsOfDay";
import TrainBot from "./TrainBot";

const Training = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  return (
    <div className="main-content-div">
      <div className={styles.modalContainer}>
        <div className={styles.parentCard}>
          {/* Hardcoded Training Bot */}
          <div className={styles.row}>
            <div className={styles.rowTitle} onClick={() => handleRowClick(1)}>
              TRAINING BOT
            </div>
            {expandedRow === 1 && (
              <div className={styles.rowDetails}>
                {/* Details for Training Bot */}
                <TrainBot />
              </div>
            )}
          </div>

          {/* Hardcoded Tactics of the Day */}
          <div className={styles.row}>
            <div className={styles.rowTitle} onClick={() => handleRowClick(2)}>
              TACTICS OF THE DAY
            </div>
            {expandedRow === 2 && (
              <div className={styles.rowDetails}>
                {/* Details for Tactics of the Day */}
                <TacticsOfDay />
              </div>
            )}
          </div>

          {/* Hardcoded Courses */}
          <div className={styles.row}>
            <div className={styles.rowTitle} onClick={() => handleRowClick(3)}>
              COURSES
            </div>
            {expandedRow === 3 && (
              <div className={styles.rowDetails}>
                {/* Details for Courses */}
                <ComingSoon />
              </div>
            )}
          </div>

          {/* Hardcoded 1 on 1 Coaching */}
          <div className={styles.row}>
            <div className={styles.rowTitle} onClick={() => handleRowClick(4)}>
              1 ON 1 COACHING
            </div>
            {expandedRow === 4 && (
              <div className={styles.rowDetails}>
                {/* Details for 1 on 1 Coaching */}
                <ComingSoon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Training.propTypes = {
  expandedRow: PropTypes.number,
};

export default Training;
