// SmallCard.js
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../css/SmallCard.module.css';

const SmallCard = ({ label = 'Default Label', score = 0 }) => {
  const validScore = Number(score);
  const maxHeight = 80;
  const height = (validScore / 100) * maxHeight;

  const graphRef = useRef(null);

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.style.setProperty('--bar-height', `${height}px`);
    }
  }, [height]);

  return (
    <div className={styles.smallCard}>
      <div className={styles.leftHalf}>
        <div className={styles.topHalf}>
          <div className={styles.bigFiveScore}>{validScore}%</div>
        </div>
        <div className={styles.bottomHalf}>
          <div className={styles.bigFiveLabel}>{label}</div>
        </div>
      </div>
      <div className={styles.rightHalf}>
        <div className={styles.graphContainer}>
          <div
            ref={graphRef}
            className={styles.colorfulGraph}
          ></div>
        </div>
      </div>
    </div>
  );
};

SmallCard.propTypes = {
  label: PropTypes.string,
  score: PropTypes.number.isRequired,
};

export default SmallCard;
