.leftPanel {
  width: 220px;
  height: 100vh;
  background: linear-gradient(to bottom, #ffffff, #f9f9f9);
  padding: 20px;
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #e0e0e0;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.logo img {
  height: 40px;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.menuItem {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  padding: 12px 20px;
  margin: 5px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  position: relative;
}

.menuItem:hover {
  background-color: #e0e0e0;
  color: #000000;
  transform: scale(1.02);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.menuItem.active {
  background-color: #d0d0d0;
  color: #000000;
}

.menuIcon {
  margin-right: 15px;
  font-size: 20px;
}

.menuText {
  flex: 1;
}
