import React, { useState, useRef } from 'react';
import styles from '../../../../css/Modal.module.css'; // Ensure this path is correct
import { FaTimes, FaExternalLinkAlt } from 'react-icons/fa'; // Import the external link icon

const Modal1 = ({ isOpen, onClose, emotion, messages }) => {
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const modalRef = useRef(null);

  const handleMouseDown = (event) => {
    setDragging(true);
    setOffset({
      x: event.clientX - modalRef.current.getBoundingClientRect().left,
      y: event.clientY - modalRef.current.getBoundingClientRect().top,
    });
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      const left = event.clientX - offset.x;
      const top = event.clientY - offset.y;
      modalRef.current.style.left = `${left}px`;
      modalRef.current.style.top = `${top}px`;
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  if (!isOpen) return null;

  const getTagStyle = (source) => {
    switch (source) {
      case 'email':
        return styles.emailTag;
      case 'slack':
        return styles.slackTag;
      default:
        return styles.defaultTag;
    }
  };

  return (
    <div
      className={styles.modalOverlay}
      onClick={(e) => e.target === e.currentTarget && onClose()}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div
        className={styles.modalContent}
        ref={modalRef}
        onMouseDown={handleMouseDown}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={styles.closeButton}
          onClick={onClose}
          aria-label="Close modal"
        >
          <FaTimes /> {/* Cross icon */}
        </button>
        <h5>Top 10 Messages Reflecting Emotion: {emotion}</h5>
        <div className={styles.messageList}>
          {messages.length === 0 ? (
            <p>No messages available.</p>
          ) : (
            messages.map((message, index) => (
              <div key={index} className={styles.messageRow}>
                <p className={styles.messageText}>{message.text}</p>
                <div className={styles.messageDetails}>
                  <span className={`${styles.tag} ${getTagStyle(message.source)}`}>
                    {message.source}
                  </span>
                  <span
                    className={styles.redirectIcon}
                    title="Read more"
                    onClick={() => window.open(message.link, '_blank', 'noopener noreferrer')}
                  >
                    <FaExternalLinkAlt />
                  </span>
                  <span className={styles.timestamp}>{message.timestamp}</span>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal1;
