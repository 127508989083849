import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const ContactSection = () => {
    return (
        <section id="Contact" className="contact py-5">
            <Container className="contact-container">

                <h1 className="text-center pt-5 display-5">Contact Us</h1>
                <Form className="p-5">
                    <Form.Group className="mb-4" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Your Name"/>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Your Email"/>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formMessage">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Your Message"/>
                    </Form.Group>
                    <a href="/" className="btn btn-outline-white btn-lg"
                       data-wow-delay=".6s">Submit</a>
                </Form>
            </Container>
        </section>
    );
};

export default ContactSection;