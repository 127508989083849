import React from "react";
import "./OptionPill.css";

const OptionPillLabel = ({ label }) => {
  return <div className="option-pill-label">{label}</div>;
};

const OptionPill = ({ label, options, selectedValue, onValueChange }) => {
  return (
    <div className="option-pill">
      <OptionPillLabel label={label} />
      <div className="option-pill-select-container">
        <select
          id="select-click"
          value={selectedValue}
          onChange={onValueChange}
          className="option-pill-select"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default OptionPill;
