.form_left {
    max-width: 50%;
    border-right: 1px dashed grey;
    padding: 30px;
    min-height: 100%;
    background-color: #561596;
    border-radius: 30px 0px 0px 30px;
}

.static_header_content {
    text-align: center;
    font-family: cursive;
    color: darkslategrey;
}

.form_left .static_header {
    font-size: 24px;
    font-weight: 600;
    font-family: cursive;
    margin-top: 15px;
}

.form_left .form_left_img {
    width: 90%;
    margin-top: 75px;
}

.content_right button {
    background: darkgreen;
    color: white;
    font-size: 16px;
    width: 100%;
    /* font-weight: 700; */
    margin-bottom: 10px;
    height: 40px;
    border: 1px solid;
    padding: 0px;
    margin-top: 25px;
}
.content_right button:hover {
    background: #00390f;
    cursor: pointer;
}

.content_right {
    min-width: 50%;
    padding: 20px;
}

.login_link {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.brand_link {
    font-size: 28px;
    font-weight: 600;
    color: wheat;
}