import React from "react";
import { useState } from "react";
import { useMenu } from '../../../../MenuProvider';
import "./MyCircle.css";
import OptionPill from "../../fragments/pills/OptionPill";
import SearcBox from "../../../../api/users/SearchBox";
import { Link } from "react-router-dom";
import {
  FaSortUp,
  FaSortDown,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaSort,
} from "react-icons/fa";
import friends from "../../../../Friend";
import traitColors from "../../../../traits";


const sortFields = ["Name", "Score", "Personality"];
const sortOrderOptions = ["Asc", "Desc"];

const FriendName = ({ friend }) => (
  <div className="friend-name">
    <h3>{friend.name}</h3>
    <p>{friend.email}</p>
  </div>
);

const FriendPersonalityBar = ({ trait, percentage, color }) => (
  <div className="personality-bar">
    <div className="trait-label">{trait}</div>
    <div className="bar-container">
      <div
        className="bar"
        style={{ width: `${percentage}%`, backgroundColor: color }}
      ></div>
    </div>
    <div className="percentage-label">{percentage}%</div>
  </div>
);

const FriendPersonalityBarList = ({ personality }) => (
  <div className="personality-bars">
    {personality.map((traitObj) => (
      <FriendPersonalityBar
        key={traitObj.trait}
        trait={traitObj.trait}
        percentage={traitObj.percentage}
        color={traitColors[traitObj.trait]}
      />
    ))}
  </div>
);

const Chip = ({ emotion }) => (
  <div className="emotion-chip">
    {emotion.emotion}
    <div className="percentage-circle">{emotion.percentage}%</div>
    <div className="tooltip">{`This represents ${emotion.emotion}`}</div>
  </div>
);

const FriendEmotionChipList = ({ friend }) => (
  <div className="emotion-chips">
    {friend.emotions.map((emotionObj) => (
      <Chip key={emotionObj.emotion} emotion={emotionObj} />
    ))}
  </div>
);

const CircularProgress = ({ percentage, color }) => {
  const radius = 50;
  const stroke = 10;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg className="circular-progress" height={radius * 2} width={radius * 2}>
      <circle
        className="circle-background"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className="circle-progress"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          strokeDashoffset,
          strokeDasharray: `${circumference} ${circumference}`,
          stroke: color,
        }}
      />
      <text
        x="50%"
        y="50%"
        className="circle-text"
        textAnchor="middle"
        dy=".3em"
      >
        {percentage}
      </text>
    </svg>
  );
};

const FriendCoordinationScore = ({ friend }) => (
  <div className="friend-score">
    <CircularProgress percentage={friend.score} color={friend.color} />
    <p>Coordination Score</p>
  </div>
);

const FriendCard = ({ friend }) => {
    const { setSelectedMenu, selectedMenu } = useMenu();
    return (

  <Link to={`/user/${friend.id}`} className="friend-card-link" onClick={() => setSelectedMenu("User View")}>
    <div className="friend-card">
      <img
        src={friend.profilePic}
        alt={`${friend.name}'s profile`}
        className="profile-pic"
      />
      <div className="friend-info">
        <FriendName friend={friend} />
        <FriendPersonalityBarList personality={friend.personality} />
        <FriendEmotionChipList friend={friend} />
        <FriendCoordinationScore friend={friend} />
      </div>
    </div>
  </Link>
)};

const FriendList = ({ friends }) => (
  <div className="friend-list">
    {friends.map((friend) => (
      <FriendCard key={friend.id} friend={friend} />
    ))}
  </div>
);

const MyCircle = () => {
  const [sortField, setSortField] = useState(sortFields[0]);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 5;

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const toggleSortField = () => {
    const currentIndex = sortFields.indexOf(sortField);
    const nextIndex = (currentIndex + 1) % sortFields.length;
    setSortField(sortFields[nextIndex]);
  };

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "Asc" ? "Desc" : "Asc");
  };

  const filteredFriends = friends.filter(
    (friend) =>
      friend.name.toLowerCase().includes(searchQuery) ||
      friend.email.toLowerCase().includes(searchQuery),
  );

  const sortedFriends = [...filteredFriends].sort((a, b) => {
    const compareA = a[sortField.toLowerCase()];
    const compareB = b[sortField.toLowerCase()];

    if (sortOrder === "Asc") {
      return compareA < compareB ? -1 : compareA > compareB ? 1 : 0;
    } else {
      return compareA > compareB ? -1 : compareA < compareB ? 1 : 0;
    }
  });

  const totalPages = Math.ceil(sortedFriends.length / itemsPerPage);
  const displayedFriends = sortedFriends.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  return (
    <div className="main-content-div">
      <div className="my-circle">
        <div className="sort-options-right">
          <div className="search-box">
            <input
              type="text"
              placeholder="Search for User"
              value={searchQuery}
              onChange={handleSearch}
              className="search-input form-control"
            />
          </div>
          <div className="sort-field-toggle" onClick={toggleSortField}>
            <FaSort size={24} />
            <span>{sortField}</span>
          </div>
          <div className="sort-order-toggle" onClick={toggleSortOrder}>
            {sortOrder === "Asc" ? (
              <FaSortUp size={24} />
            ) : (
              <FaSortDown size={24} />
            )}
            <span>{sortOrder}</span>
          </div>
        </div>
        <FriendList friends={displayedFriends} />
        <div className="pagination">
          <button
            onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
            className="pagination-button"
            disabled={currentPage === 1}
          >
            <FaChevronLeft /> Previous
          </button>
          <span className="pagination-text">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage(Math.min(totalPages, currentPage + 1))
            }
            className="pagination-button"
            disabled={currentPage === totalPages}
          >
            Next <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyCircle;
