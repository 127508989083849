import React, { useState, useRef, useEffect } from "react";
import styles from "../../../../css/ChatWindow.module.css";
import { sendMessageToTrainbot } from "../../../../api/chatbot/PsyGenieChatBot";

const TrainBot = () => {
  const [messages, setMessages] = useState([]);
  const [inputEnabled, setInputEnabled] = useState(false);
  const [currentSituation, setCurrentSituation] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const users = [
    {
      id: 1,
      name: "User1",
      personality: [
        { trait: "O", percentage: 70 },
        { trait: "C", percentage: 60 },
        { trait: "E", percentage: 80 },
        { trait: "A", percentage: 75 },
        { trait: "N", percentage: 40 },
      ],
      emotions: [
        { emotion: "Content", percentage: 70 },
        { emotion: "Anxious", percentage: 10 },
        { emotion: "Fearful", percentage: 5 },
        { emotion: "Joyful", percentage: 10 },
        { emotion: "Relieved", percentage: 5 },
      ],
    },
    {
      id: 2,
      name: "User2",
      personality: [
        { trait: "O", percentage: 85 },
        { trait: "C", percentage: 65 },
        { trait: "E", percentage: 75 },
        { trait: "A", percentage: 80 },
        { trait: "N", percentage: 30 },
      ],
      emotions: [
        { emotion: "Optimistic", percentage: 60 },
        { emotion: "Disappointed", percentage: 15 },
        { emotion: "Frustrated", percentage: 10 },
        { emotion: "Curious", percentage: 10 },
        { emotion: "Confident", percentage: 5 },
      ],
    },
    {
      id: 3,
      name: "User3",
      personality: [
        { trait: "O", percentage: 75 },
        { trait: "C", percentage: 70 },
        { trait: "E", percentage: 85 },
        { trait: "A", percentage: 65 },
        { trait: "N", percentage: 45 },
      ],
      emotions: [
        { emotion: "Calm", percentage: 65 },
        { emotion: "Worried", percentage: 10 },
        { emotion: "Confused", percentage: 8 },
        { emotion: "Energized", percentage: 12 },
        { emotion: "Surprised", percentage: 5 },
      ],
    },
    {
      id: 4,
      name: "User4",
      personality: [
        { trait: "O", percentage: 60 },
        { trait: "C", percentage: 75 },
        { trait: "E", percentage: 65 },
        { trait: "A", percentage: 70 },
        { trait: "N", percentage: 50 },
      ],
      emotions: [
        { emotion: "Hopeful", percentage: 55 },
        { emotion: "Bored", percentage: 20 },
        { emotion: "Annoyed", percentage: 5 },
        { emotion: "Motivated", percentage: 15 },
        { emotion: "Startled", percentage: 5 },
      ],
    },
    {
      id: 5,
      name: "User5",
      personality: [
        { trait: "O", percentage: 65 },
        { trait: "C", percentage: 60 },
        { trait: "E", percentage: 70 },
        { trait: "A", percentage: 68 },
        { trait: "N", percentage: 55 },
      ],
      emotions: [
        { emotion: "Proud", percentage: 60 },
        { emotion: "Melancholic", percentage: 15 },
        { emotion: "Upset", percentage: 10 },
        { emotion: "Interested", percentage: 10 },
        { emotion: "Shocked", percentage: 5 },
      ],
    },
  ];

  const personalityFullFormMap = {
    "O": "OPENNESS",
    "C": "CONSCIENTIOUSNESS",
    "E": "EXTRAVERSION",
    "A": "AGREEABLENESS",
    "N": "NEUROTICISM"
  };

  const situations = [
    {
      situation:
        "how will you communicate to above user, that he is not doing good job at work and need to improve",
      evaluation:
        "Evaluate whether the user prioritizes empathy, delegation, and contingency planning.",
      userid: 1,
    },
    {
      situation:
        "how will you communicate to above user, that he is not doing good job at work and need to improve",
      evaluation:
        "Assess the user's ability to communicate, negotiate deadlines, and manage time effectively.",
      userid: 2,
    },
    {
      situation:
        "how will you communicate to above user, that he is not doing good job at work and need to improve",
      evaluation:
        "Determine the user's sense of responsibility, problem-solving skills, and prioritization.",
      userid: 3,
    },
    {
      situation:
        "how will you communicate to above user, that he is not doing good job at work and need to improve",
      evaluation:
        "Analyze how the user adapts to new roles, leads unfamiliar teams, and handles increased responsibility.",
      userid: 4,
    },
    {
      situation:
        "how will you communicate to above user, that he is not doing good job at work and need to improve",
      evaluation:
        "Examine the user's communication skills, diplomacy, and ability to voice dissent constructively.",
      userid: 5,
    },
  ];

  const getEvaluationCriteria = async (user, situation, message) => {
    try {
      const response = await sendMessageToTrainbot(user, situation, message);
      return response; // Assuming the response contains the evaluation criteria in the 'data' field
    } catch (error) {
      console.error("Error fetching evaluation:", error);
      return "Failed to evaluate your response. Please try again later.";
    }
  };

  const handleNewSituation = () => {
    const randomIndex = Math.floor(Math.random() * situations.length);
    const randomSituation = situations[randomIndex];
    const user = users[randomIndex];
    setCurrentSituation(randomSituation);
    setCurrentUser(user);
    setMessages([]);
    setMessages([
      { text: `Personality :\n${formatPersonality(user.personality)}\n Emotions: \n${formatEmotions(user.emotions)}`, sender: "bot" },
      { text: randomSituation.situation, sender: "bot" },
    ]);
    setInputEnabled(true);
  };

  const formatPersonality = (personality) => {
    return personality.map((p) => `${personalityFullFormMap[p.trait]}: ${p.percentage}%`).join('  ');
  };

  const formatEmotions = (emotions) => {
    return emotions.map((e) => `${e.emotion}: ${e.percentage}%`).join('  ');
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.elements.messageInput.value.trim();
    if (message) {
      setMessages([
        ...messages,
        { text: message, sender: "user" },
        { text: "Evaluating your response...", sender: "bot", isLoading: true }, // Show evaluation placeholder
      ]);
      e.target.reset();
      setInputEnabled(false);

      // Simulate evaluation process with delay
      setTimeout(async () => {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.isLoading
              ? {
                  text: `Evaluating your response: "${message}". Please wait...`,
                  sender: "bot",
                }
              : msg,
          ),
        );
      }, 2000); // 2-second delay for initial evaluation

      // Simulate detailed evaluation after a delay
      try {
        const evaluationReport = await getEvaluationCriteria(
          currentUser,
          currentSituation,
          message,
        );

        // Update the message with the detailed evaluation after receiving the response
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.text.includes("Evaluating your response")
              ? {
                  text: `Here is your evaluation report:\n\n${evaluationReport}`,
                  sender: "bot",
                }
              : msg,
          ),
        );
      } catch (error) {
        console.error("Error during evaluation:", error);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.text.includes("Evaluating your response")
              ? {
                  text: "An error occurred while evaluating your response. Please try again later.",
                  sender: "bot",
                }
              : msg,
          ),
        );
      }
    }
  };

  return (
    <div className={styles.chatWindowContainer}>
      <div className={styles.chatWindow}>
        {/* Instruction Text */}
        <div className={styles.instruction}>
          Instruction: To train yourself, click on the "New Situation" button.
          This will provide you with a hypothetical situation to respond to.
          After you answer, you will receive an evaluation result based on your
          response.
        </div>

        <div className={styles.chatHeader}>
          <span>Chat Bot</span>
          <button
            className={styles.newSituationButton}
            onClick={handleNewSituation}
          >
            New Situation
          </button>
        </div>

        <div className={styles.chatMessages}>
          {messages.map((message, index) => (
            <div
              key={index}
              ref={index === messages.length - 1 ? lastMessageRef : null}
              className={`${styles.chatBubble} ${styles[message.sender]} ${message.isLoading ? styles.loadingBubble : ""}`}
            >
              {message.text}
            </div>
          ))}
        </div>

        <form className={styles.chatInput} onSubmit={handleSendMessage}>
          <input
            type="text"
            name="messageInput"
            placeholder="Type your answer..."
            disabled={!inputEnabled}
          />
          <button type="submit" disabled={!inputEnabled}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default TrainBot;
