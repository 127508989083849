/* SmallCard.module.css */
.smallCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 200px;
  height: 120px;
  text-align: center;
  display: flex;
  flex-direction: row;
  margin: 2px;
  transition: all 0.3s ease;
}

.smallCard:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
}

.leftHalf {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  height: 100%;
  flex: 3;
}

.topHalf, .bottomHalf {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topHalf {
  margin-bottom: 5px;
}

.bigFiveLabel {
  font-size: 15px;
  font-weight: 800;
  color: #333;
  margin-bottom: 5px;
}

.bigFiveScore {
  font-size: 30px;
  font-weight: bold;
  color: #6200ea;
}

.rightHalf {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.graphContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.colorfulGraph {
  width: 20px;
  height: 0;
  background: linear-gradient(to top, #ff6f61, #6a1b9a);
  border-radius: 5px;
  animation: loadBar 2s forwards;
}


@keyframes loadBar {
  from {
    height: 0;
  }
  to {
    height: var(--bar-height);
  }
}
