import React from 'react';
import { FaBell } from 'react-icons/fa';
import styles from '../../../../css/Notification.module.css'; // Update with your actual CSS module path

const NotificationIcon = ({ count }) => {
  return (
    <div className={styles.notificationWrapper}>
      <div className={styles.bellIconWrapper}>
        <FaBell className={styles.bellIcon} />
        {count > 0 && <div className={styles.notificationCount}>{count}</div>}
      </div>
    </div>
  );
};

export default NotificationIcon;
