import React from 'react';
import LeftShape from "../../../../img/landing/left-shape.svg"
import LeftDots from "../../../../img/landing/left-dots.svg"
import mainImage from "../../../../img/landing/Instant_insight.png"
const PersonalInsight = () => {
    return (
        <>
            <section id="Feature" className="personal-insight py-5 mt-5">
                <div className="personal-insight-container container">
                    <div className="row">
                        <div className="col-md-6 left-section-col">
                            <img src={mainImage} alt="" className="w-100 shape shape-main"/>
                            <img src={LeftShape} alt="" className="shape shape-1"/>
                            <img src={LeftDots} alt="" className="shape shape-2"/>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h2 className="fs-1 fw-bold">Better Understand the Individual</h2>
                            <p className="fs-4">"Better Understand the Individual: Explore Key Insights into Personality, Behavior, and Emotions"—designed to help you gain a deeper understanding of unique personal traits, moods, and behaviors for enhanced self-awareness and growth.</p>
                            <a href="/" className="btn btn-outline-blue btn-lg"
                               data-wow-delay=".6s">Know more</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PersonalInsight;