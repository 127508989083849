/* SuggestionBoard.module.css */

.suggestionBoardContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.suggestionList {

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
  gap: 15px;
}

/* Base card styling */
.suggestionCard {
  position: relative;
  padding: 20px;
  border-radius: 25px; /* One side round, other side corner */
  background-color: #fff; /* White background */
  border: 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.suggestionCard:hover .animatedBorder {
  opacity: 1; /* Show on hover */
  animation: moveAlongBoundary 4s linear infinite;
}

.animatedBorder {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 2px solid;
  background: linear-gradient(
    90deg,
    rgba(255, 99, 132, 0.2),
    rgba(54, 162, 235, 0.2),
    rgba(255, 206, 86, 0.2),
    rgba(75, 192, 192, 0.2),
    rgba(153, 102, 255, 0.2),
    rgba(255, 159, 64, 0.2),
    rgba(200, 200, 200, 0.2),
    rgba(255, 99, 71, 0.2),
    rgba(32, 178, 170, 0.2),
    rgba(255, 105, 180, 0.2)
  );
  background-size: 400% 400%;
  border-radius: 0 20px 0 20px; /* Matches the card shape */
  z-index: -1;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s;
}

.suggestionCard::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  margin-top: -150%;
  margin-left: -150%;
  border-radius: inherit;
  background: #fff;
  z-index: -2;
}

@keyframes moveAlongBoundary {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.suggestionCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.suggestionText {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  text-align: center;
  z-index: 1;
  position: relative;
}
