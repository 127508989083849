// src/components/HeroSection.jsx
import React, {useState, useEffect, useRef} from 'react';
import "../../../../css/Landing.module.css";
import { Button } from 'react-bootstrap';
import style from "../../../../css/Landing.module.css";
import logoGreenText from "../../../../img/landing/logo_text_green.svg";

const HeroSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const items = ["Better understand individuals.", "Get live interaction suggestions.", "Understand your digital personality.", "Improve with tips, training, courses."];
    const homeRef = useRef(null);

    const scrollToSection = (section) => {
        const element = document.querySelector(section)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 2000); // Change item every 2 seconds

        return () => clearInterval(interval);
    }, [items.length]);
    return (
        <>
        <section id="Hero" ref={homeRef} className="hero text-white py-5 section">
            <div className="container">
                <div className="row align-items-center position-relative">
                    <div className="col-lg-6">
                        <h1 className="display-4">Empower communication with psychological insights</h1>
                        <p className="lead">Psygenie helps you understand individuals by analyzing their personality, emotion, and behavior. It provides real-time suggestions for effective interactions and customizes messages for each person. Psygenie also evaluates your own behavior, offering personalized tips and training to help you improve continuously</p>
                        <a href="#subscribe" onClick={() => scrollToSection('#subscribe')} className="btn btn-outline-white btn-lg"
                            data-wow-delay=".6s">Subscribe & Stay Informed</a>

                    </div>
                    <div className="col-lg-6">
                        <div className={style.hero_bullet_text_large}>
                            <div>
                                <ul className="animated_list">
                                    {items.map((item, index) => (
                                        <li
                                            key={index}
                                            className={`list_item ${index === currentIndex ? 'show index-' + index : 'hide'}`}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default HeroSection;