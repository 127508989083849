import React, { useState } from "react";
import logostyle from "../../../../../css/Logo.module.css";
import logo from "../../../../../img/logo.svg";
import AuthenticationService from "../../../../../api/authentication/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconWithCount from "../profileAlert/IconWithBadge";
import { faBell, faMailBulk, faTasks } from "@fortawesome/free-solid-svg-icons";

const ProfileAlerts = () => {
  const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
  const isBusinessLoggedIn = AuthenticationService.isBusinessLoggedIn();

  return (
    <>
      {!isBusinessLoggedIn && !isUserLoggedIn && (
        <section className={logostyle.landing_logo_container}>
          <Navbar variant="light" expand="lg">
            <Navbar.Brand href="/">
              <img src={logo} alt="PsyGenie" width="40px" />
              <span style={{ fontweight: "600", color: "#000000" }}>
                &nbsp;PsyGenie
              </span>
            </Navbar.Brand>
          </Navbar>
        </section>
      )}

      {isBusinessLoggedIn && (
        <Navbar variant="light" expand="lg">
          <Navbar.Brand href="/">PsyGenie</Navbar.Brand>
        </Navbar>
      )}

      {/*{isUserLoggedIn && (
        <section className={logostyle.logo_container}>
          <ul className="nav flex-row">
            <li className={logostyle.icon_li}>
              <IconWithCount count={5} icon={faBell} />
            </li>
          </ul>
        </section>
      )}*/}
    </>
  );
};

export default ProfileAlerts;
