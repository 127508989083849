@import url(./PsygenieCore.css);

.form_left {
  min-width: 50%;
  padding: 20px;
}

.static_header_content {
  text-align: center;
  color: darkslategrey;
}

.form_left .static_header {
  font-size: 24px;
  font-weight: 600;
  margin-top: 15px;
}

.form_left button {
  /* font-weight: 700; */
  margin-bottom: 10px;
  height: 40px;
  border: 1px solid;
  padding: 0px;
  margin-top: 25px;
}

.signup_link {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.content_right {
  background-color: #561596;
  border-radius: 0px 26px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50%;
}

.content_right img {
  width: 90%;
}

.change_password_span {
  margin-top: 50px;
}

.brand_link {
  font-size: 28px;
  font-weight: 600;
  color: indigo;
}

.text {
  color: var(--primary-color-text);
  font-size: large;
}

.forgot_password_text {
  font-size: small;
  text-decoration: none;
}

.logo_nbar {

}

.signup_link_text {
  color: var(--primary-color-text);
  text-decoration: none;
}
