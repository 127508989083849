import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/root/fragments/header/Header";
import Home from "./components/root/home/Home";
import SignUp from "./components/root/users/signUp/SignUp";
import RegisterBusiness from "./components/root/users/signUp/RegisterBusiness";
import Login from "./components/root/users/login/Login";
import UserHome from "./components/root/users/UsersHome";
import Hobbie from "./components/root/users/business/Offer/Hobbie";
import AccountUser from "./components/root/users/user/accountUser/AccountUser";
import AccountBusiness from "./components/root/users/business/AccountBusiness/AccountBusiness";
import TestForm from "./components/root/users/user/test/TestForm";
import CreateOffer from "./components/root/users/business/Offer/CreateOffer";
import MyHobbies from "./components/root/users/user/MyHobbies";
import ProtectedRoutesGuest from "./components/protectedRoutes/ProtectedRoutesGuest";
import ProtectedRoutesUser from "./components/protectedRoutes/ProtectedRoutesUser";
import ProtectedRoutesBusiness from "./components/protectedRoutes/ProtectedRoutesBusiness";
import EditUserProfile from "./components/root/users/user/accountUser/EditUserProfile";
import EditBusinessProfile from "./components/root/users/business/AccountBusiness/EditBusinessProfile";
import UpdateOffer from "./components/root/users/business/Offer/UpdateOffer";
import PasswordChange from "./components/root/users/login/forgottenPassword/PasswordChange";
import SetUpNewPassword from "./components/root/users/login/forgottenPassword/SetUpNewPassword";
import ProfilePage from "./components/root/users/myProfile/MyProfileInfo";
import Sidebar from "./components/root/fragments/header/Sidebar";
import AuthenticationService from "./api/authentication/AuthenticationService";
import MyCircle from "./components/root/users/circle/MyCircle";
import UserView from "./components/root/users/myProfile/UserViewInfo";
import Action from "./components/root/users/myProfile/Action";
import { MenuProvider } from "./MenuProvider";
import PageNotFound from "./components/root/users/login/PageNotFound";
import AboutUs from "./components/root/users/myProfile/AboutUs";
import Settings from "./components/root/users/myProfile/Settings";
import Training from "./components/root/users/myProfile/Training";

import ChatButton from "./components/root/users/chatButton/ChatButton";
import friends from "./Friend";

const userLogged = AuthenticationService.isUserLoggedIn();

const people = friends;

function App() {
  return (
    <MenuProvider>
      <Router>
        <div className="App">
          <Header />
          {AuthenticationService.isUserLoggedIn() && <Sidebar />}
          <ChatButton people={people} />
          <Routes>
            <Route>
              <Route path="/aboutus" element={<AboutUs />} />
            </Route>
            <Route element={<ProtectedRoutesGuest />}>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/register-business" element={<RegisterBusiness />} />
              <Route path="/login" element={<Login />} />
              <Route path="/change-password" element={<PasswordChange />} />
              <Route path="/password/:id" element={<SetUpNewPassword />} />
            </Route>
            <Route element={<ProtectedRoutesUser />}>
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/edit-profile" element={<EditUserProfile />} />
              <Route path="/circle" element={<MyCircle />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/user/:id" element={<UserView />} />
              <Route path="/action" element={<Action />} />
              <Route path="/setting" element={<Settings />} />
              <Route path="/training" element={<Training />} />
              <Route path="*" element={<Navigate to="/profile" replace />} />
            </Route>
            <Route element={<ProtectedRoutesBusiness />}>
              <Route
                path="/edit-business-profile"
                element={<EditBusinessProfile />}
              />
              // <Route path="/edit-offer" element={<UpdateOffer />} />
              // <Route path="/business-home" element={<UserHome />} />
              //{" "}
              <Route path="/account-business" element={<AccountBusiness />} />
              // <Route path="/create-offer" element={<CreateOffer />} />
              //{" "}
              <Route path="/account-business" element={<AccountBusiness />} />
              // <Route path="/offer/:id" element={<Hobbie />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </MenuProvider>
  );
}

export default App;
