import React from "react";
import styles from "../../../../../css/ProfileCard.module.css";  // Ensure this path is correct
import AuthenticationService from "../../../../../api/authentication/AuthenticationService";
import { NavLink } from "react-router-dom";

const ProfileCard = () => {
    return (
        <div className={styles.card}>
            <div className={styles.options}>
                <div className={styles.logoutButton} onClick={AuthenticationService.logout}>
                    <NavLink className={styles.logoutLink} to="/">
                        Logout
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
