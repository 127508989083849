/* Container for all tactics in a table format */
.tacticsContainer {
  margin: 10px;
  position: relative; /* Make sure the container is positioned for relative children */
  max-height: 390px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Style for the table */
.tacticsTable {
  width: 95%;
  border-collapse: separate; /* Allow border-radius on rows */
  border-spacing: 0 10px; /* Add space between rows to make rounded corners more visible */
  margin-top: 20px;
}

/* Style for each table row */
.tableRow {
  position: relative; /* Position relative for absolute positioning of the tooltip */
  transition: transform 0.3s, box-shadow 0.3s, border-color 0.3s;
  cursor: pointer;
  background-color: #f0f0f0; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow to give depth */
  border: 2px solid transparent; /* Default border is transparent */
}

/* 3D hover effect for table rows */
.tableRow:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
  border: 2px solid red; /* Example color for the border on hover */
}

/* Style for table cells */
.tableRow td {
  padding: 10px;
  text-align: left;
  border-bottom: none; /* Remove bottom border to allow rounded corners */
  position: relative; /* Needed for positioning the tooltip inside cells */
}

/* Tooltip for table row */
.tooltip {
  visibility: hidden;
  background-color: #fff; /* White background for the tooltip */
  color: #333; /* Dark text color for better readability */
  text-align: center;
  padding: 10px;
  position: fixed;
  z-index: 10000; /* Ensure tooltip is above all other content */
  bottom: 0; /* Position above the row */
  left: 50%; /* Center horizontally relative to the row */
  transform: translateX(-50%) translateY(-10px); /* Center horizontally and adjust vertical position */
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  white-space: normal; /* Allow text to wrap */
  border-radius: 8px; /* Rounded corners for the tooltip */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional: Add shadow to tooltip */
  width: 500px; /* Limit the width of the tooltip */
  overflow: hidden; /* Hide overflow text */
  word-wrap: break-word; /* Ensure text wraps within the box */
}

/* Show tooltip on row hover */
.tableRow:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(10px); /* Adjust vertical position if needed */
}

