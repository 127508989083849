import React, { useState } from 'react';
import styles from '../../../../css/TacticsOfDay.module.css';
import SuggestionModal from './SuggestionModal';

// Feedback Sandwich: Give constructive criticism by sandwiching it between positive comments.
// Groupthink Avoidance: Encourage diversity of thought and challenge consensus to prevent poor decision-making.
// Decoy Effect: Introduce a less attractive option to make another choice seem more appealing
// Priming: Use subtle cues or triggers to influence thoughts and behaviors in a specific direction.
// Scarcity: Highlight the uniqueness or limited availability of resources or opportunities to increase their perceived value

const suggestions = [
  {
    id: 1,
    text: 'Feedback Sandwich',
    description: 'A method of delivering criticism by placing it between two positive comments to make the feedback easier to accept.',
    examples: [
      "Example 1: 'You did a great job on the project; however, I think we could improve on the time management aspect. Overall, your attention to detail was excellent.'",
      "Example 2: 'I appreciate your effort in completing the report. One thing that needs improvement is the accuracy of the data. Keep up the good work with your thorough research.'"
    ],
  },
  {
    id: 2,
    text: 'Groupthink Avoidance',
    description: 'Techniques to prevent groupthink, ensuring diverse opinions are considered in decision-making.',
    examples: [
      "Example 1: 'Encourage team members to express their opinions and concerns before making a final decision.'",
      "Example 2: 'Invite outside experts to provide alternative perspectives during discussions.'"
    ],
  },
  {
    id: 3,
    text: 'Decoy Effect',
    description: 'A strategy where a third, less attractive option makes another option more appealing.',
    examples: [
      "Example 1: 'Offering a small popcorn at $3, a medium at $6.50, and a large at $7 makes the large seem like a better deal.'",
      "Example 2: 'Presenting an expensive item alongside a more expensive decoy option makes the original item seem reasonably priced.'"
    ],
  },
  {
    id: 4,
    text: 'Priming',
    description: 'A technique where exposure to one stimulus influences the response to a subsequent stimulus.',
    examples: [
      "Example 1: 'Showing an image of a happy face before asking someone to rate their mood may lead to a higher rating.'",
      "Example 2: 'Hearing the word ‘yellow’ makes it easier to recognize the word ‘banana’.'"
    ],
  },
  {
    id: 5,
    text: 'Scarcity',
    description: 'A tactic that increases perceived value by emphasizing limited availability or exclusivity.',
    examples: [
      "Example 1: 'Limited-time offer: Only 5 items left in stock!'",
      "Example 2: 'Exclusive event: Invitation-only access for premium members.'"
    ],
  },
];

const borderColors = [
  '#ff6f61',
  '#4db6ac',
  '#ffb74d',
  '#7986cb',
  '#81c784'
];

const TacticsOfDay = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const handleCardClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
  };

  const closeModal = () => {
    setSelectedSuggestion(null);
  };

  return (
    <div className={styles.suggestionBoardContainer}>
      <div className={styles.suggestionList}>
        {suggestions.map((suggestion, index) => {
          const borderColor = borderColors[index % borderColors.length];
          return (
            <div
              key={suggestion.id}
              className={styles.suggestionCard}
              style={{ borderColor: borderColor }}
              onClick={() => handleCardClick(suggestion)}
            >
              <p className={styles.suggestionText}>{suggestion.text}</p>
              <div
                className={styles.animatedBorder}
                style={{ borderColor: borderColor }}
              />
            </div>
          );
        })}
      </div>

      {selectedSuggestion && (
        <SuggestionModal
          suggestion={selectedSuggestion}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default TacticsOfDay;
