import axios from "axios";

const chatbotAxios = axios.create({
  baseURL: process.env.REACT_APP_CHATBOT_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const sendMessageToChatbot = async (person, message) => {
  try {
    let finalMessage = "";
    const name = person.name;
    finalMessage = "Name: " + name + "\n\n";
    person.personality.map((per) => {
      switch (per.trait) {
        case "O":
          finalMessage = finalMessage + `Openness: ${per.percentage}%\n`;
          break;
        case "C":
          finalMessage =
            finalMessage + `Conscientiousness: ${per.percentage}%\n`;
          break;
        case "E":
          finalMessage = finalMessage + `Extraversion: ${per.percentage}%\n`;
          break;
        case "A":
          finalMessage = finalMessage + `Agreeableness: ${per.percentage}%\n`;
          break;
        case "N":
          finalMessage = finalMessage + `Neuroticism: ${per.percentage}%\n`;
          break;
      }
    });
    finalMessage = finalMessage + "\n";
    person.emotions.map((emotion) => {
      finalMessage =
        finalMessage + `${emotion.emotion}: ${emotion.percentage}%\n`;
    });
    finalMessage = finalMessage + "\n";
    finalMessage = finalMessage + message;

    const result = await chatbotAxios.post("/chatbot", {
      message: finalMessage,
    });
    return result.data.response;
  } catch (error) {
    console.error(
      "Error fetching data:",
      error.response ? error.response.data : error.message,
    );
    return "something went wrong";
  }
};

export const sendMessageToTrainbot = async (user, situation, message) => {
  try {
    let finalMessage = "";
    const name = user.name;
    finalMessage = "Name: " + name + "\n\n";
    user.personality.map((per) => {
      switch (per.trait) {
        case "O":
          finalMessage = finalMessage + `Openness: ${per.percentage}%\n`;
          break;
        case "C":
          finalMessage =
            finalMessage + `Conscientiousness: ${per.percentage}%\n`;
          break;
        case "E":
          finalMessage = finalMessage + `Extraversion: ${per.percentage}%\n`;
          break;
        case "A":
          finalMessage = finalMessage + `Agreeableness: ${per.percentage}%\n`;
          break;
        case "N":
          finalMessage = finalMessage + `Neuroticism: ${per.percentage}%\n`;
          break;
      }
    });
    finalMessage = finalMessage + "\n";
    user.emotions.map((emotion) => {
      finalMessage =
        finalMessage + `${emotion.emotion}: ${emotion.percentage}%\n`;
    });
    finalMessage = finalMessage + "\n";
    finalMessage =
      finalMessage + "Hypothetical situation: " + situation.situation;
    finalMessage = finalMessage + "\n";
    finalMessage = finalMessage + "User response: " + message;

    const result = await chatbotAxios.post("/trainbot", { message: message });
    return result.data.response;
  } catch (error) {
    console.error(
      "Error fetching data:",
      error.response ? error.response.data : error.message,
    );
    return "something went wrong.";
  }
};

export const subscribe = async (email, recaptcha) => {
  const result = await chatbotAxios.post("/subscribe", {
    email: email,
    recaptcha: recaptcha,
  });
  return result;
};
