import React from "react";
import { useParams } from "react-router-dom";
import "./UserView.css";

// Dummy data for demo purposes
const friends = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@example.com",
    profilePic:
      "https://hips.hearstapps.com/hmg-prod/images/apple-ceo-steve-jobs-speaks-during-an-apple-special-event-news-photo-1683661736.jpg?crop=1xw:0.52889xh;center,top&resize=1200:*",
    score: 85,
    color:"#ff6f61",
    personality: [
     { trait: "O", percentage: 70 },
      { trait: "C", percentage: 60 },
      { trait: "E", percentage: 80 },
      { trait: "A", percentage: 75 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 70 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 5 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "janesmith@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/62d599ede3ff49f348f9b9b4/0x0.jpg?format=jpg&crop=821,821,x155,y340,safe&height=416&width=416&fit=bounds",
    score: 90,
    color:"#4db6ac",
    personality: [
      { trait: "O", percentage: 85 },
      { trait: "C", percentage: 65 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 80 },
      { trait: "N", percentage: 30 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 60 },
      { emotion: "Sad", percentage: 15 },
      { emotion: "Angry", percentage: 10 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 3,
    name: "Alice Johnson",
    email: "alicejohnson@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/5d6ae14f673aa300083caf99/0x0.jpg?format=jpg&crop=2923,2926,x3051,y26,safe&height=416&width=416&fit=bounds",
    score: 88,
    color:"#ffb74d",
    personality: [
      { trait: "O", percentage: 75 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 85 },
      { trait: "A", percentage: 65 },
      { trait: "N", percentage: 45 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 65 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 8 },
      { emotion: "Excited", percentage: 12 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 4,
    name: "Bob Brown",
    email: "bobbrown@example.com",
    profilePic:
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    score: 82,
    color:"#7986cb",
    personality: [
      { trait: "O", percentage: 60 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 65 },
      { trait: "A", percentage: 70 },
      { trait: "N", percentage: 50 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 55 },
      { emotion: "Sad", percentage: 20 },
      { emotion: "Angry", percentage: 5 },
      { emotion: "Excited", percentage: 15 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 5,
    name: "Charlie Davis",
    email: "charliedavis@example.com",
    profilePic:
      "https://t3.ftcdn.net/jpg/06/01/17/18/360_F_601171827_GwbDHEuhisbGFXRfIpXFhtf7wAvsbLut.jpg",
    score: 79,
    color:"#81c784",
    personality: [
      { trait: "O", percentage: 65 },
      { trait: "C", percentage: 60 },
      { trait: "E", percentage: 70 },
      { trait: "A", percentage: 68 },
      { trait: "N", percentage: 55 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 60 },
      { emotion: "Sad", percentage: 15 },
      { emotion: "Angry", percentage: 10 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 6,
    name: "Diana Evans",
    email: "dianaevans@example.com",
    profilePic:
      "https://miro.medium.com/v2/resize:fit:1400/0*0fClPmIScV5pTLoE.jpg",
    score: 84,
    color:"#ff6f61",
    personality: [
      { trait: "O", percentage: 70 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 78 },
      { trait: "A", percentage: 72 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 65 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 8 },
      { emotion: "Excited", percentage: 12 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 7,
    name: "Eve Fisher",
    email: "evefisher@example.com",
    profilePic:
      "https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8",
    score: 81,
    color:"#4db6ac",
    personality: [
      { trait: "O", percentage: 72 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 68 },
      { trait: "N", percentage: 45 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 60 },
      { emotion: "Sad", percentage: 12 },
      { emotion: "Angry", percentage: 5 },
      { emotion: "Excited", percentage: 15 },
      { emotion: "Surprised", percentage: 8 },
    ],
  },
  {
    id: 8,
    name: "Frank Green",
    email: "frankgreen@example.com",
    profilePic:
      "https://hips.hearstapps.com/hmg-prod/images/apple-ceo-steve-jobs-speaks-during-an-apple-special-event-news-photo-1683661736.jpg?crop=1xw:0.52889xh;center,top&resize=1200:*",
    score: 77,
    color:"#ffb74d",
    personality: [
      { trait: "O", percentage: 68 },
      { trait: "C", percentage: 65 },
      { trait: "E", percentage: 70 },
      { trait: "A", percentage: 75 },
      { trait: "N", percentage: 50 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 55 },
      { emotion: "Sad", percentage: 20 },
      { emotion: "Angry", percentage: 10 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 9,
    name: "Grace Hill",
    email: "gracehill@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/62d599ede3ff49f348f9b9b4/0x0.jpg?format=jpg&crop=821,821,x155,y340,safe&height=416&width=416&fit=bounds",
    score: 89,
    color:"#7986cb",
    personality: [
      { trait: "O", percentage: 75 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 85 },
      { trait: "A", percentage: 70 },
      { trait: "N", percentage: 35 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 70 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 5 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 10,
    name: "Henry Isaac",
    email: "henryisaac@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/5d6ae14f673aa300083caf99/0x0.jpg?format=jpg&crop=2923,2926,x3051,y26,safe&height=416&width=416&fit=bounds",
    score: 86,
    color:"#81c784",
    personality: [
      { trait: "O", percentage: 70 },
      { trait: "C", percentage: 80 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 65 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 65 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 10 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 11,
    name: "Ivy James",
    email: "ivyjames@example.com",
    profilePic:
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    score: 83,
    color:"#ff6f61",
    personality: [
      { trait: "O", percentage: 65 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 70 },
      { trait: "A", percentage: 72 },
      { trait: "N", percentage: 55 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 60 },
      { emotion: "Sad", percentage: 15 },
      { emotion: "Angry", percentage: 8 },
      { emotion: "Excited", percentage: 12 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
];

const traitColors = {
  O: '#ff6f61', // Openness
  C: '#4db6ac', // Conscientiousness
  E: '#ffb74d', // Extraversion
  A: '#7986cb', // Agreeableness
  N: '#81c784', // Neuroticism
};

const FriendName = ({ friend }) => (
  <div className="friend-name">
    <h3>{friend.name}</h3>
    <p>{friend.email}</p>
  </div>
);

const FriendPersonalityBar = ({ trait, percentage, color }) => (
  <div className="personality-bar">
    <div className="trait-label">{trait}</div>
    <div className="bar-container">
      <div className="bar" style={{ width: `${percentage}%`, backgroundColor: color }}></div>
    </div>
    <div className="percentage-label">{percentage}%</div>
  </div>
);

const FriendPersonalityBarList = ({ personality }) => (
  <div className="personality-bars">
    {personality.map((traitObj) => (
      <FriendPersonalityBar
        key={traitObj.trait}
        trait={traitObj.trait}
        percentage={traitObj.percentage}
        color={traitColors[traitObj.trait]}
      />
    ))}
  </div>
);

const FriendEmotionChip = ({ emotion }) => (
  <div className="emotion-chip">
    {emotion.emotion}
    <div className="percentage-circle">{emotion.percentage}%</div>
    <div className="tooltip">{`This represents ${emotion.emotion}`}</div>
  </div>
);

const FriendEmotionChipList = ({ friend }) => (
  <div className="emotion-chips">
    {friend.emotions.map((emotionObj) => (
      <FriendEmotionChip key={emotionObj.emotion} emotion={emotionObj} />
    ))}
  </div>
);

const CircularProgress = ({ percentage, color }) => {
  const radius = 50;
  const stroke = 10;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg className="circular-progress" height={radius * 2} width={radius * 2}>
      <circle
        className="circle-background"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className="circle-progress"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        style={{
          strokeDashoffset,
          strokeDasharray: `${circumference} ${circumference}`,
          stroke: color
        }}
      />
      <text
        x="50%"
        y="50%"
        className="circle-text"
        textAnchor="middle"
        dy=".3em"
      >
        {percentage}
      </text>
    </svg>
  );
};

const UserView = () => {
  const { id } = useParams();
  const friend = friends.find((f) => f.id === parseInt(id));

  if (!friend) {
    return <div>User not found</div>;
  }

  return (
    <div className="my-user-view">
      <div className="user-card">
        <img
          src={friend.profilePic}
          alt={`${friend.name}'s profile`}
          className="profile-pic-large-make"
        />
        <div className="user-info">
          <h2>{friend.name}</h2>
          <p>{friend.email}</p>
        </div>
        <div className="user-score">
          <CircularProgress percentage={friend.score} color={friend.color} />
          <p>Coordination Score</p>
        </div>
      </div>
    </div>
  );
};

export default UserView;