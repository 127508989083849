.footer_hobbie_details {
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3C000000,endColorstr=#3C000000);
  zoom: 1;
  margin: 0 auto;
  width: 280px;
  color: #494969;
  padding-bottom: 15px;
}

.footer {
  margin-top: 40px;
  margin: 2% 5%;
  bottom: 0px;
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3C000000,endColorstr=#3C000000);
  zoom: 1;
  width: 90%;
  color: #494969;
  position: relative;
  top: 43px;
}


.footer_cover {
  position: absolute;
  height: 40px;
  margin-top: 40px;
  margin-left: 5%;
  bottom: 0px;
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#3C000000,endColorstr=#3C000000);
  zoom: 1;
  width: 90%;
  color: #494969;
}

@media all and (max-width: 600px) {

  .footer_hobbie_details {
    font-size: 14px;
    padding-left: 3%;
  }
}
