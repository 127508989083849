/* Modal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal is on top of other content */
}

.modalContent {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  max-width: 80%;
  width: 800px;
  position: absolute;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(45deg, #ff6f61, #4db6ac) border-box;
  transition: background 0.3s ease, border 0.3s ease;
}

.modalContent:hover {
  background: linear-gradient(#fff, #f9f9f9) padding-box,
              linear-gradient(45deg, #ff6f61, #4db6ac) border-box;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.messageList {
  margin-top: 20px;
}

.messageRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.messageRow:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.messageText {
  flex: 1;
  margin: 0;
  font-size: 14px;
}

.messageDetails {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
}

.emailTag {
  background-color: rgba(255, 111, 97, 0.5);
}

.slackTag {
  background-color: rgba(121, 134, 203, 0.7);
}

.defaultTag {
  background-color: #999; /* Grey for any other source */
}

.redirectIcon {
  color: #4db6ac;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.redirectIcon:hover {
  color: #388e7b;
}

.timestamp {
  font-size: 12px;
  color: #999;
  margin-left: 10px;
}
