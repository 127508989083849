import React from 'react';
import LeftPanel from './LeftPanel';
import SmallCard from './SmallCard';
import SmallCard1 from './SmallCard1'; //temporary
import styles from '../../../../css/ProfilePage.module.css';
import NotificationIcon from './Notification';
import EmotionDashboard from './EmotionDashboard';
import Leaderboard from './Leaderboard';
import SuggestionBoard from './SuggestionBoard'
import UserView from "../circle/UserView";
import DosAndDonts from "./DosAndDonts";
import TopMessages from "./TopMessages";


const UserViewInfo = () => {

  const bigFiveData = [
    { label: 'Openness', score: '85' },
    { label: 'Conscientiousness', score: '90' },
    { label: 'Extraversion', score: '70' },
    { label: 'Agreeableness', score: '75' },
    { label: 'Neuroticism', score: '60' }
  ];

  const traits = {
    "Creativity": "High creativity leads to innovative thinking.",
    "Curiosity": "An inquisitive nature helps in learning new things.",
    "Imagination": "A strong imagination can lead to inventive solutions."
  };

const tactics = {
  "Feedback Sandwich": {
    score: 7,
    tooltip: "Give constructive criticism by sandwiching it between positive comments."
  },
  "Groupthink Avoidance": {
    score: 9,
    tooltip: "Encourage diversity of thought and challenge consensus to prevent poor decision-making."
  },
  "Decoy Effect": {
    score: 6,
    tooltip: "Introduce a less attractive option to make another choice seem more appealing."
  },
  "Priming": {
    score: 8,
    tooltip: "Use subtle cues or triggers to influence thoughts and behaviors in a specific direction."
  },
  "Scarcity": {
    score: 10,
    tooltip: "Highlight the uniqueness or limited availability of resources or opportunities to increase their perceived value."
  },
  "Contrast Principle": {
    score: 5,
    tooltip: "Place options side by side to highlight differences."
  },
  "Mirroring": {
    score: 4,
    tooltip: "Mimic behavior or speech patterns to build rapport."
  },
  "Yes, But": {
    score: 3,
    tooltip: "Agreeing with the other person’s point of view first, then gently introducing your own perspective."
  },
  "Reverse Psychology": {
    score: 2,
    tooltip: "Suggest the opposite of what you want to provoke the desired action."
  },
  "Humility Check": {
    score: 1,
    tooltip: "Acknowledge your own limitations or mistakes before providing feedback."
  }
};

  const sampleEmotions = [
    { label: 'Happiness', severity: 'High', score: '85' },
    { label: 'Sadness', severity: 'Medium', score: '60' },
    { label: 'Anger', severity: 'Low', score: '45' },
    { label: 'Fear', severity: 'High', score: '75' },
    { label: 'Surprise', severity: 'Medium', score: '65' },
    { label: 'Disgust', severity: 'Low', score: '40' },
    { label: 'Joy', severity: 'High', score: '90' },
    { label: 'Trust', severity: 'Medium', score: '70' }
  ];


  return (
      <div className="main-content-div">
        <div className={styles.profilePage}>
          <div className={styles.profileContent}>
            {/* Top Right Section */}
            <div className={styles.topSection}>
              <div className="row">
                <div className="col-md-3">
                  <UserView/>
                </div>
                <div className="col-md-9">
                  <div className="alignTopMessages"><TopMessages/></div>
                </div>
              </div>
            </div>
            <div className={styles.cardContainer}>
              {/*           <ProfileCard /> */}
              <div className={styles.smallCards}>
                <div className="row">
                {bigFiveData.map((data, index) => (
                  <div className="col">
                    <SmallCard1 key={index} label={data.label} score={data.score} colorIndex={index} traits={traits}/>
                  </div>
                ))}
                </div>
              </div>
            </div>
            <EmotionDashboard emotions={sampleEmotions} tactics={tactics}/>
            <DosAndDonts/>
          </div>
        </div>
      </div>
  );
};

export default UserViewInfo;
