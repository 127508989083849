/* Chat container */
.chatWindowContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

/* Chat window */
.chatWindow {
  width: 1000px;
  max-height: 600px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Chat header */
.chatHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.newSituationButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.newSituationButton:hover {
  background-color: #0056b3;
}

/* Chat messages */
.chatMessages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  position: relative; /* For positioning the loading animation */
}

/* Chat bubble */
.chatBubble {
  max-width: 80%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.chatBubble.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  margin-left: auto;
}

/* Style for messages from the bot with animation */
.chatBubble.bot {
    background-color: #fff;
    border: 5px solid transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(270deg, #4285F4, #34A853, #FBB605, #EA4335);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    align-self: flex-start;
    animation: gradientMove 3s ease infinite;
}

/* Keyframes for gradient animation */
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Input field and send button styling */
.chatInput {
  display: flex;
  align-items: center;
  width: 100%;
}

.chatInput input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 15px;
  margin-right: 10px;
}

.chatInput button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 15px;
  cursor: pointer;
}

.chatInput button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.chatInput button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Styling for chat bubbles */
.chatBubble {
  max-width: 80%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

/* Style for messages from the user */
.chatBubble.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
  margin-left: auto;
}

/* Loading animation */
.loadingDots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Absolute positioning to overlay in chatMessages */
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #007bff; /* Use the color you provided */
  border-radius: 50%;
  margin: 0 3px;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.dot:nth-child(4) {
  animation-delay: 0.6s;
}

.dot:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.instruction {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
}
