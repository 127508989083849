import React from 'react';
import mainImage from "../../../../img/landing/real-time-chat.png";
import LeftShape from "../../../../img/landing/left-shape-purple.svg";
import LeftDots from "../../../../img/landing/left-dots-purple.svg";
const InstantInsight = () => {
    return (
        <>
            <section id="contact" className="instant-insight py-5 mt-5">
                <div className="instant-insight-container container">
                    <div className="row">
                        <div className="col-md-6  left-section-col">
                            <img src={mainImage} alt="" className="w-100 shape shape-main"/>
                            <img src={LeftShape} alt="" className="shape shape-1"/>
                            <img src={LeftDots} alt="" className="shape shape-2"/>
                        </div>
                        <div className="col-md-6 align-self-center">
                            <h2 className="fs-1 fw-bold">Real-Time Guidance</h2>
                            <p className="fs-4">"Real-Time Guidance: Instant Suggestions for Every Situation"—offering on-the-spot advice tailored to your current context to help you navigate any challenge effectively.</p>
                            <a href="/" className="btn btn-outline-blue btn-lg"
                               data-wow-delay=".6s">Know more</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default InstantInsight;