import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="bg-dark text-white py-4">
            <Container>
                <Row>
                    <Col md={6}>
                        <p>&copy; 2024 PsyGenie. All Rights Reserved.</p>
                    </Col>
                    <Col md={6} className="text-md-right">
                        <div className="float-end">
                            <a href="https://www.facebook.com" className="text-white  ms-5"><i
                                className="fab fa-facebook-f ms-5"></i></a>
                            <a href="https://www.twitter.com" className="text-white  ms-5"><i
                                className="fab fa-twitter"></i></a>
                            <a href="https://www.instagram.com" className="text-white ms-5"><i
                                className="fab fa-instagram"></i></a>
                        </div>

                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;