/* Action.module.css */

.actionContainer {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  padding: 20px;
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border: 4px solid transparent; /* Make room for the colorful border */
  border-image-slice: 1; /* Use the entire gradient for the border */
}

.cardTitle {
  font-size: 20px;
  margin-bottom: 15px;
  color: #494969; /* Dark color for the title */
  text-align: center;
}

.table {
  margin-top: 10px;
}

.row {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  gap: 10px; /* Add some spacing between elements */
}

.row:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cell {
  flex: 3; /* Give more space to the message text */
  font-size: 14px;
  display: flex;
  align-items: center;
}

.details {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align tags and timestamp to the right */
  flex: 2; /* Adjust based on how much space you want for the tags and timestamp */
  gap: 8px;
  flex-wrap: wrap; /* Allows wrapping of elements if necessary */
}

.name {
  font-size: 14px;
  font-weight: bold;
  color: #494969;
}

.tag, .emotionTag, .sourceTag {
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #fff;
}

.emailTag {
  background-color: rgba(255, 111, 97, 0.9);
}

.slackTag {
  background-color: rgba(121, 134, 203, 0.9);
}

.emotionTag {
  background-color: #ff6f61; /* Default color, can be overridden by specific classes */
}

.happyEmotion {
  background-color: #ff6f61;
}

.excitedEmotion {
  background-color: #4db6ac;
}

.stressedEmotion {
  background-color: #ffb74d;
}

.neutralEmotion {
  background-color: #7986cb;
}

.relaxedEmotion {
  background-color: #81c784;
}

.sourceTag {
  background-color: #f5f5f5;
  color: #494969;
}

.redirectIcon {
  color: #4db6ac;
  font-size: 16px;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.redirectIcon:hover {
  color: #388e7b;
}

.timestamp {
  font-size: 12px;
  color: black;
}
