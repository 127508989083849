import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from '../../../../css/EmotionDashboard.module.css';
import Modal from './Modal1';
import Tactics from './Tactics';

const EmotionDashboard = ({ emotions, tactics }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmotion, setSelectedEmotion] = useState('');
  const [messages, setMessages] = useState([]);

  const barColors = [
    '#ff6f61',
    '#4db6ac',
    '#ffb74d',
    '#7986cb',
    '#81c784',
    '#ff6f61',
    '#4db6ac',
    '#ffb74d',
  ];

  const dummyMessages = {
    Happiness: [
      { text: 'Sub: Lets Do Party', source: 'email', timestamp: '2024-08-22 10:00', link: '#' },
      { text: 'Sub: Successfully Completed Project', source: 'email', timestamp: '2024-08-22 11:00', link: '#' },
      { text: 'Channel: Psygenie', source: 'slack', timestamp: '2024-08-22 10:00', link: '#' },
      { text: 'Channel: Psygenie', source: 'slack', timestamp: '2024-08-22 11:00', link: '#' },
      { text: 'Sub: Lets Do Party', source: 'email', timestamp: '2024-08-22 11:00', link: '#' },
      { text: 'Sub: Successfully Completed Project', source: 'email', timestamp: '2024-08-22 10:00', link: '#' },
      { text: 'Sub: Lets Meetup', source: 'email', timestamp: '2024-08-22 11:00', link: '#' },
    ],
    Sadness: [
      { text: 'Sub: Farewell', source: 'email', timestamp: '2024-08-22 09:00', link: '#' },
      { text: 'Channel: Psygenie', source: 'slack', timestamp: '2024-08-22 10:30', link: '#' },
    ],
    // Add other emotions as needed
  };

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const emotion = emotions[index].label;
      setSelectedEmotion(emotion);
      setMessages(dummyMessages[emotion] || []);
      setModalOpen(true);
    }
  };

  const barData = {
    labels: emotions.map(emotion => emotion.label),
    datasets: [
      {
        label: 'Emotions',
        data: emotions.map(emotion => parseFloat(emotion.score.replace('%', ''))),
        backgroundColor: barColors.slice(0, emotions.length), // Transparent fill
        borderColor: barColors.slice(0, emotions.length),
        borderWidth: 2,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
    },
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        stacked: false,
        barPercentage: 0.3,
        categoryPercentage: 1.0,
        ticks: {
          color: '#333',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#333',
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // Rounded corners for bars
        borderWidth: 2,
        borderColor: barColors.slice(0, emotions.length), // Add border color
        hoverBorderWidth: 3, // Thicker border on hover
        hoverBorderColor: 'rgba(0, 0, 0, 0.7)', // Darker border color on hover
        shadowColor: 'rgba(0, 0, 0, 0.5)', // Shadow color
        shadowBlur: 10, // Amount of blur for shadow
        shadowOffsetX: 3, // Horizontal shadow offset
        shadowOffsetY: 3, // Vertical shadow offset
      },
    },
    onClick: handleBarClick, // Add click handler
  };

  return (
      <div className={styles.emotionDashboardWrapper}>
        <div className="row">
          <div className="col-md-6">
            <div className={styles.emotionDashboard}>
              <div className={styles.emotionHeader}>
                <span className={styles.emotionTitle}>Top 8 Emotions</span>
              </div>
              <div className={styles.chartContainer}>
                <Bar data={barData} options={barOptions}/>
              </div>
              <Modal
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  emotion={selectedEmotion}
                  messages={messages}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.emotionDashboard}>
              <div className={styles.tacticsSection}>
                <h2 className={styles.tacticsTitle}>Tactics</h2>
                <Tactics tactics={tactics}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

        export default EmotionDashboard;
