/* DosAndDonts.module.css */

.container {

  gap: 20px;
  margin-top: 20px;
  background-color: #f5f5f5;
}

.card {
  flex: 1;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.cardTitle {
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}

.card .doCardTitle {
  color: #81c784 !important; /* Forcing the green color */
}

.card .dontCardTitle {
  color: #ff6f61 !important; /* Forcing the red color */
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  text-align: left;
}

.listItem:last-child {
  border-bottom: none;
}
