.landing_logo_container {
  display: flex;
  flex: 1 1;
}

.logo_container {
  display: flex;
  flex: 1 1;
  margin-left: 50%;
  justify-content: flex-end;
}

.logo {
  font-weight: 400px;
  margin: 2%;
  color: #494969;
}

.imgHeader {
  width: 30px;
  height: 30px;
  margin-bottom: 30px;
  padding: 0%;
  margin: 0%;
}

.icon_li {
  background: darkslateblue;
  padding: 5px 10px;
  border-radius: 30px;
  color: aliceblue;
  margin-right: 20px;
}



@media all and (max-width: 900px) {
  .logo_container {
    position: relative;
    left: 30px;
  }
}

@media all and (max-width: 650px) {
  .logo_container {
    position: relative;
    left: 3%;
    margin-top: 2%;
  }
}
