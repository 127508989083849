import React from "react";
import styles from "../../../../css/Chip.module.css";

const Chip = ({ text, circleText, circleColor = "#5864ff", tooltipText }) => {
  return (
    <div className={styles.chip}>
      <span className={styles.chip_text}>{text}</span>
      {circleText && (
        <span
          className={styles.chip_circle_text}
          style={{ backgroundColor: circleColor }}
        >
          {circleText}
        </span>
      )}
      {tooltipText && (
        <span className={styles.chip_tooltip}>{tooltipText}</span>
      )}
    </div>
  );
};

export default Chip;
