.test_form {
  margin: 20px auto;
  background: linear-gradient(to right, #696989, #dcdce6);
  color: white;
  width: 800px;
  min-height: 500px;
  height: min-content;
  border-radius: 15px;
  padding: 80px;
  box-shadow: 10px 10px 12px 12px rgba(248, 246, 246, 0.75);
  display: flex;
  justify-content: space-evenly;
}

.test_form_end {
  margin: 20px auto;
  background: linear-gradient(to right, #696989, #dcdce6);
  color: white;
  width: 800px;
  min-height: 500px;
  height: min-content;
  border-radius: 15px;
  padding: 80px;
  box-shadow: 10px 10px 12px 12px rgba(248, 246, 246, 0.75);
}

.test_end {
  margin: auto;
  width: 100%;
}

.button {
  padding: 10px 10px 10px 10px;
  content: " ";
  font-size: 1em;
  background-color: transparent;
  display: inline-block;
  margin-left: 10px;
  color: white;
  width: 100px;
  height: 50px;
  visibility: visible;
  border: 1px solid white;
  padding-left: 3px;
  border-radius: 5px;
  margin-bottom: 60px;
  margin-top: 30px;
}

.link_home {
  color: white;
}

.question_section {
  width: 100%;
  position: relative;
  padding-right: 30px;
}

.question_count {
  margin-bottom: 10px;
}

.question_count span {
  font-size: 28px;
}

.question_text {
  width: 100%;
  height: 30px;
  margin-bottom: 12px;
  font-size: 16px;
}

.answer_section {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.test_button {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background: linear-gradient(to right, #696989, #494969);
  border-radius: 15px;
  display: flex;
  padding: 15px 20px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid transparent;
  cursor: pointer;
}

@media all and (max-width: 850px) {
  .test_content {
    margin: auto;
    width: 100%;
  }

  .test_form {
    display: block;
    width: 90%;
    padding: 0;
    margin: 20px 5%;
  }

  .test_form_end {
    display: block;
    width: 90%;
    padding: 0;
    margin: 5%;
  }

  .test_button {
    width: 90%;
    margin: auto;
    height: 50px;
    margin: 5px;
  }

  .question_section {
    padding: 60px 30px 30px 30px;
  }

  .answer_section {
    margin: 20px 20px 40px 20px;
  }

  .test_end {
    padding: 100px 50px;
  }

  .test_button {
    padding: 10px;
    margin-bottom: 10px;
  }
}
