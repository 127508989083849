import React, { useState, useRef, useEffect } from "react";
import styles from "../../../../css/Header.module.css";
import Navbar from "./navbar/Navbar";
import { useLocation } from "react-router-dom";
import ProfileAlerts from "./profileAlert/ProfileAlerts";
import { useMenu } from "../../../../MenuProvider"; // Adjust path as needed
import Avatar from "./profileAlert/Avatar"; // Import the Avatar component
import ProfileCard from "./profileAlert/ProfileCard"; // Import the ProfileCard component
import AuthenticationService from "../../../../api/authentication/AuthenticationService";
import background from "../background/Background";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import IconWithCount from "../../fragments/header/profileAlert/IconWithBadge";
import logostyle from "../../../../css/Logo.module.css";

const Header = React.memo(() => {
  const { selectedMenu } = useMenu();
  const [showProfileCard, setShowProfileCard] = useState(false);
  const location = useLocation();
  const hideHeader =
    location.pathname === "/login" ||
    location.pathname === "/change-password" ||
    location.pathname === "/signup";
  const userLogged = AuthenticationService.isUserLoggedIn();

  const cardRef = useRef(null); // Reference for the ProfileCard

  const handleAvatarClick = () => {
    setShowProfileCard((prev) => !prev);
  };

  useEffect(() => {
    // Close the ProfileCard if clicked outside
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowProfileCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (hideHeader) {
    return null; // Hide header if on login page
  }

  return (
    <>
      {userLogged && (
        <header className={styles.header}>
          <div className={styles.headerContent}>
            <h1 className={styles.selectedMenu}>{selectedMenu}</h1>
          </div>
          <ProfileAlerts />
          <div className={styles.headerWrapper}>
            {userLogged && (
                <section className={logostyle.logo_container}>
                  <ul className="nav flex-row">
                    <li className={logostyle.icon_li}>
                      <IconWithCount count={5} icon={faBell} />
                    </li>
                  </ul>
                </section>
            )}
            <div className={styles.avatarAndText} onClick={handleAvatarClick}>
              <li>
                <Avatar />
              </li>
              <div className={styles.textWrapper}>
                <p>Joshua Esguia</p>
              </div>
            </div>
          </div>
          {showProfileCard && (
            <div ref={cardRef} className={styles.profileCardWrapper}>
              <ProfileCard />
            </div>
          )}
        </header>
      )}
      {!userLogged && (
        <header
          className="navbar  sticky-top flex-md-nowrap p-0 border-bottom landing_header_menu"
          style={{ background: "whitesmoke", position: "fixed", width: "100%" }}
        >
          <div className="container">
            <ProfileAlerts />
            <Navbar />
          </div>
        </header>
      )}
    </>
  );
});

export default Header;
