import React, { useEffect, useState } from "react";
import { useMenu } from "../../../../MenuProvider";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation from react-router-dom
import styles from "../../../../css/Sidebar.module.css";
import pglogo from "../../../../img/logo.svg";

const Sidebar = () => {
  const { setSelectedMenu, selectedMenu } = useMenu(); // Include selectedMenu from context if available
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname); // Use current path as initial state

  // Define the menu items
  const menuItems = [
    { name: "My Personality", path: "/profile" },
    { name: "My Circle", path: "/circle" },
    { name: "Action", path: "/action" },
    { name: "Training", path: "/training" },
    { name: "Setting", path: "/setting"},
    { name: "About Us", path: "/aboutus" }
  ];

  // Update activeMenu and selectedMenu based on current path or default to the first menu item
useEffect(() => {
  const matchingItem = menuItems.find((item) => item.path === location.pathname);
  if (matchingItem) {
    setActiveMenu(location.pathname);
    setSelectedMenu(matchingItem.name);
  }
}, [location.pathname, setSelectedMenu]);

  const handleMenuClick = (menu, path) => {
    setSelectedMenu(menu);
    setActiveMenu(path); // Update activeMenu state
  };

return (
  <div className={styles.sidebar}>
    <div className={styles.sidebar_panel}>
      <div className={styles.sidebar_menu_logo}>
        <img src={pglogo} alt="PsyGenie" width="35px"/> &nbsp;
        <span>PsyGenie</span>
      </div>
      <div className={styles.sidebar_menu_navigation}>
        Menu Navigation
      </div>
      <ul className="nav flex-column" style={{minWidth: "200px"}}>
        {menuItems.map((item) => (
            <li
                key={item.path}
                className={`nav-item ${activeMenu === item.path ? styles.active : ""}`}
            >
              <Link
                  to={item.path}
                  className={`nav-link ${activeMenu === item.path ? styles.activeLink : ""}`}
                  onClick={() => handleMenuClick(item.name, item.path)}
              >
                <i
                    className={`fa fa-${item.name.toLowerCase().replace(" ", "-")}`}
                    aria-hidden="true"
                ></i>{" "}
                {item.name}
              </Link>
            </li>
        ))}
      </ul>
      <div className={styles.sidebar_panel_footer}>
        <i className="fa fa-copyright fa-fw"/> PsyGenie 2024. All rights
        reserved.
      </div>
    </div>
  </div>
);

};

export default Sidebar;
