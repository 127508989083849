.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f5f5f5;
  color: #333;
}

.content {
  text-align: center;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 48px;
  margin-bottom: 20px;
  color: #4db6ac;
}

.subtitle {
  font-size: 18px;
  margin-bottom: 40px;
  color: #7986cb;
}

.loader {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}
