.modalOverlay {
  position: fixed; /* Use fixed positioning for modal overlay */
  top: 0;
  left: 0; /* Align overlay to cover the entire viewport */
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 90%; /* Allow the modal to adjust to different screen sizes */
  max-height: 90vh; /* Limit the height to 90% of the viewport height */
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* Make modal content scrollable if it exceeds the max-height */
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Center align the title */
  color: #333; /* Example color */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.rowContainer {
  margin-top: 20px;
}

.row {
  padding: 10px;
  border: 1px solid #4db6ac;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.row:hover {
  background: #f0f0f0;
}

.expanded {
  background: #e0e0e0;
}

.rowDetails {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.row {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 10px 0;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rowTitle {
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.rowDetails {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.modalContainer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.parentCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding/border in width */
  max-height: 80vh; /* Set maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

.expanded {
  background-color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.row:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}
