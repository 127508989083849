.hobby_details_page {
  width: 100%;
  height: 100%;
}

.hobbie_main {
  height: auto;
  width: 100%;
  margin-bottom: 2%;
}

.hobbie_container_home {
  padding: 50px;
  width: 60%;
  height: 100%;
  margin: 0px auto;
  box-shadow: -2px 0px 33px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: transparent;
  grid-template-columns: 1fr repeat(9, minmax(auto, 100px)) 1fr;
  grid-gap: 20px;
}

.intro {
  font-size: 14px;
}

.cards {
  width: 100%;
  grid-column: 2 / span 11;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 60px));
  position: relative;
  grid-gap: 25px;
}

.rapper {
  flex: 1 1 auto;
  background: gold;
  overflow: auto;
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
}

.card {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.5 ease 0s;
  background: linear-gradient(to right, #494969, #696989);
  width: auto;
  box-shadow: -2px 0px 33px 0px rgb(0 0 0 / 15%);
  /* padding: 10px; */
}

.card_image_container {
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}
.card_image_container img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text_medium {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: white;
}

.card_content {
  padding: 20px;
}

.card_content p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.card_title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: white;
}
.card_info {
  display: flex;
  align-self: end;
  align-items: center;
}
.card_price {
  margin-left: auto;
  padding: 5px, 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: white;
}

.card:hover {
  transform: translateY(-7px);
}

.introduction_home {
  margin: auto;
  align-items: center;
}
.intro_text {
  margin: auto;
  padding-bottom: 30px;
  margin-bottom: 100px;
}
.intro_text h1 {
  font-size: 44px;
  font-weight: 900;
  background: linear-gradient(to right, #494969, #696989);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro_text p {
  width: 280px;
  margin: auto;
  margin-top: 5px;
  font-size: 14px;
  color: #585772;
}

/* .cta */
.buttuns {
  padding: 20px 0px 0px 0px;
  width: 280px;
  margin: auto;
}
/* .cta_second */
.btn {
  border: 2px solid #c36cbb;
  background: #c36cbb;
  color: white;
  width: 150px;
  height: 50px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

/* .cta_second_s */
.link {
  color: #c36cbb;
  background: #c36cbb;
  width: 150px;
  height: 50px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  border: 2px solid #c36cbb;
}
@media all and (min-width: 1024px) {
  .hobbie_container_home {
    position: relative;
    top: 80%;
  }
}
@media all and (max-width: 1400px) {
  .hobbie_container_home {
    width: 100%;
  }
}
@media all and (min-width: 850px) {
  .intro_text {
    position: relative;
    left: 60px;
  }

  .intro {
    font-size: 14px;
  }
}

@media all and (max-width: 950px) {
  .hobbie_container_home {
    width: 100%;
    padding: 20px;
  }
}

@media all and (max-width: 767px) {
  .intro_text {
    margin-top: 30px;
    font-size: small;
    width: 100%;
    padding-left: 10%;
  }

  .hobbie_main {
    margin: 0%;
    margin-bottom: 5%;
  }

  .hobbie_container_home {
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  .hobbie_home_content {
    background-color: transparent;
    margin: 0;
  }

  .rapper {
    width: 100%;
    height: auto;
    margin: auto;
  }

  .text_medium {
    font-size: 14px;
  }

  .cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  .card_price {
    font-size: auto;
    padding-left: 5px;
  }
}
