/* EmotionDashboard.module.css */

.emotionDashboardWrapper {
  max-width: 100%; /* Adjust as necessary */
  margin-top:20px;
}

.emotionDashboard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.emotionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.emotionTitle {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.chartContainer {
  width: 100%;
  height: 400px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tacticsCard {
  width: 45%; /* Align tactics card to the right side */
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tacticsTitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.chipsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.chip {
  background-color: #e0e0e0;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chip:hover {
  background-color: #bdbdbd;
}

.chip[title]:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  z-index: 10;
}
