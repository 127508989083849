/* Base styles for the small card container */
.smallCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Distribute cards evenly */
  gap: 10px; /* Space between cards */
}

/* Styles for individual small cards */
.smallCard {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px;
  transition: all 0.3s ease;
  position: relative; /* For positioning tooltips */
  flex-grow: 1; /* Allow cards to grow */
  flex-basis: 200px; /* Initial width */
  max-width: 100%; /* Ensure cards don't exceed container width */
  box-sizing: border-box; /* Include padding/border in width */
  word-wrap: break-word; /* Ensure content wraps */
  cursor: pointer;
}

/* Hover effect for small cards */
.smallCard:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
  z-index:10
}

/* Label styles within the card */
.label {
  font-size: 14px;
  font-weight: 900; /* Increased boldness */
  color: #452c63; /* Text color */
  margin-bottom: 10px;
  text-transform: uppercase; /* Capitalize all letters for added emphasis */
  letter-spacing: 1px; /* Add spacing between letters for more emphasis */
}

/* Container for the ring SVG */
.ringContainer {
  margin-top: 10px;
}

/* Background ring styles */
.ringBackground {
  fill: none;
  stroke: #ddd; /* Background ring color */
}

/* Ring progress styles */
.ringProgress {
  fill: none;
  stroke: #ff6f61; /* Default color, can be overridden */
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset 0.3s ease;
}

/* Color variations for the ring progress */
.ringProgress.color1 { stroke: #ff6f61; } /* Coral */
.ringProgress.color2 { stroke: #4db6ac; } /* Teal */
.ringProgress.color3 { stroke: #ffb74d; } /* Orange */
.ringProgress.color4 { stroke: #7986cb; } /* Indigo */
.ringProgress.color5 { stroke: #81c784; } /* Green */

/* Text within the ring */
.ringText {
  font-size: 16px;
  font-weight: bold;
  fill: #6200ea; /* Purple color for the text */
}

/* Container for trait tags */
.traitsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Space between tags */
  margin-top: 10px;
  justify-content: center;
  width: 100%; /* Ensure container takes full width */
  position: relative; /* Relative positioning for tooltips */
  align-items: flex-start;
}

/* Style for each trait tag */
.traitTag {
  background-color: #f0f0f0; /* Light grey background for tags */
  border-radius: 4px;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
  position: relative;
  font-size: 12px;
  color: #333;
  max-width: 100%; /* Ensure tags don't overflow */
  overflow-wrap: break-word; /* Handle long words */
  white-space: nowrap; /* Prevent wrapping within tags */
  z-index: 10; /* Ensure tags are on top of the tooltip */
}

/* Increase z-index of the active trait tag */
.traitTag:hover {
  z-index: 20; /* Increase z-index on hover to ensure tooltip is on top */
}

/* Base styles for the tooltip content */
.content {
  background-color: rgba(255, 255, 255, 0.9); /* Slightly opaque background */
  color: #452c63;
  padding: 12px 16px; /* Add padding for card-like appearance */
  border-radius: 8px; /* Rounded corners for the card */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Card shadow for 3D effect */
  position: absolute;
  white-space: normal; /* Allow text to wrap */
  z-index: 100; /* Base z-index for tooltips */
  opacity: 0;
  transform: perspective(600px) rotateX(0deg); /* 3D effect */
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none; /* Ensure content does not interfere with hover events */
  max-width: calc(100vw - 20px); /* Prevent content from exceeding viewport width */
  overflow: hidden; /* Ensure content does not overflow */
}

/* Show content on hover with 3D effect */
.traitTag:hover .content {
  opacity: 1;
  transform: translateX(-50%) translateY(-10px) perspective(600px) rotateX(10deg); /* Center content and adjust vertical positioning */
  z-index: 1000; /* High z-index to ensure it's on top */
}

/* Arrow indicator for content */
.content::before {
  content: "";
  position: absolute;
  top: 100%; /* Adjust based on content position */
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent; /* Arrow pointing down */
}

/* Color variations for content to match ring colors */
.content.color1 {
  background-color: rgba(255, 255, 255, 1); /* Coral with reduced opacity */
}

.content.color2 {
  background-color: rgba(255, 255, 255, 1); /* Teal with reduced opacity */
}

.content.color3 {
  background-color: rgba(255, 255, 255, 1); /* Orange with reduced opacity */
}

.content.color4 {
  background-color: rgba(255, 255, 255, 1); /* Indigo with reduced opacity */
}

.content.color5 {
  background-color: rgba(255, 255, 255, 1); /* Green with reduced opacity */
}

/* CSS class for bringing the most recent tooltip to the top */
.tooltip-active {
  z-index: 10; /* High z-index to ensure it's on top */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


/* Modal Overlay */
.modalOverlay {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 80%;
  max-width: 1200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left:300px;
  margin-right: 100px;
}

/* Close Button */
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.parentDiv {
  display: inline-block;
  padding: 0;
  border-radius: 8px;
  box-sizing: border-box;
}



/* Responsive Design */
@media (max-width: 768px) {
  .smallCard {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .smallCard {
    flex-basis: calc(100% - 10px);
  }
}
