import React from 'react';
import styles from '../../../../css/Leaderboard.module.css'; // Import the CSS module

const Leaderboard = ({ topPerformers }) => {
  return (
    <div className={styles.leaderboardContainer}>
      <h2 className={styles.title}>High Cordination Score</h2>
      <div className={styles.cardContainer}>
        {topPerformers.map((performer, index) => (
          <div key={index} className={styles.leaderboardCard}>
            <div className={styles.avatarContainer}>
              <img src={performer.avatar} alt={performer.name} className={styles.avatar} />
            </div>
            <div className={styles.details}>
              <div className={styles.rank}>#{index + 1}</div>
              <div className={styles.name}>{performer.name}</div>
              <div className={styles.score}>Score: {performer.score}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
