import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import style from '../../../../../css/IconWithBadge.module.css'; // Import the CSS file for styling

const IconWithBadge = ({ count, icon }) => {
    return (
        <div className={style.iconWithBadge}>
            <FontAwesomeIcon icon={icon} className="icon" />
            {count > 0 && (
                <span className={style.count_badge}>{count}</span>
            )}
        </div>
    );
};

export default IconWithBadge;