.hero_bullet_text_large {
    background-image: url('../img/landing/logo_text_large.svg');
    width: 625px;
    height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 5%;
    align-content: center;
}

.hero_bullet_text_large div {
    padding: 50px;
    font-size: 60px;
    text-align: center;
    margin-top: -50px;
}
