import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const FeaturesSection = () => {
    return (
        <section className="py-5 mt-5 mb-5"  id="Feature" >
            <Container>
                <h2 className="text-center mb-5 fw-bold" id="Feature">Our Features</h2>
                <Row>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <i className="fas fa-brain fa-3x mb-3  text-primary"></i>
                                <Card.Title className="fw-bold">Better Understand the Individual</Card.Title>
                                <Card.Text>
                                    Guide to comprehensively grasping the complexities of individual traits and emotional states.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <i className="fas fa-calendar-check fa-3x mb-3 text-primary"></i>
                                <Card.Title className="fw-bold">Discover Your Personality</Card.Title>
                                <Card.Text>
                                    Our Tool help you to understanding your personality and receive personalized strategies for growth
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="text-center">
                            <Card.Body>
                                <i className="fas fa-users fa-3x mb-3 text-primary"></i>
                                <Card.Title className="fw-bold">Real-Time Guidance</Card.Title>
                                <Card.Text>
                                    Our tool providing immediate, context-sensitive advice to navigate diverse scenarios effectively.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default FeaturesSection;