// src/components/Avatar.jsx
import React from "react";
import styles from "../../../../../css/Avatar.module.css";

const Avatar = () => {
    return (
        <img
            src="https://img.freepik.com/free-photo/view-3d-man-holding-laptop_23-2150709818.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1724630400&semt=ais_hybrid"
            alt="Avatar"
            className={styles.avatar}
        />
    );
};

export default Avatar;
