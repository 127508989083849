/* Notification.module.css */
.notificationWrapper {
  position: relative;
  display: inline-block;
}

.bellIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bellIcon {
  font-size: 40px;
  color: #666;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
