import React from 'react';
import mainImage from "../../../../img/landing/personal_personality.png";
import LeftShape from "../../../../img/landing/right-shape.svg";
import LeftDots from "../../../../img/landing/right-dots.svg";
const PersonalityNavigator = () => {
    return (
        <>
            <section id="contact" className="personality-navigator py-5">
                <div className="personality-navigator-container container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h2 className="fs-1 fw-bold">Discover Your Personality</h2>
                            <p className="fs-4">
                                "Discover Your Personality: Receive Tailored Improvement Suggestions"—uncover your unique traits and get personalized advice to enhance your strengths and address areas for growth.</p>
                            <a href="/" className="btn btn-outline-blue btn-lg"
                               data-wow-delay=".6s">Know more</a>
                        </div>
                        <div className="col-md-6 right-section-col">
                            <img src={mainImage} alt="" className="w-100 shape shape-main"/>
                            <img src={LeftShape} alt="" className="shape shape-1"/>
                            <img src={LeftDots} alt="" className="shape shape-2"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PersonalityNavigator;