import React from 'react';
import styles from '../../../../css/SuggestionBoard.module.css';

const suggestions = [
  { id: 1, text: 'Balance Creativity with Practicality' },
  { id: 2, text: 'Avoid Perfectionism' },
  { id: 3, text: 'Balance Alone Time with Socialization' },
  { id: 4, text: 'Stay Adaptable' },
  { id: 5, text: 'Assert Your Needs.' },
];

const borderColors = [
    '#ff6f61',
    '#4db6ac',
    '#ffb74d',
    '#7986cb',
    '#81c784'
];

const SuggestionBoard = () => {
    return (
        <div className={styles.suggestionBoardContainer}>
            <h2 className={styles.title}>Tips</h2>

            <div className={styles.suggestionList}>
                <div className="row">
                    {suggestions.map((suggestion, index) => {
                        const borderColor = borderColors[index % borderColors.length];
                        return (
                            <div className="col">
                                <div
                                    key={suggestion.id}
                                    className={styles.suggestionCard}
                                    style={{borderColor: borderColor}}
                                >
                                    <p className={styles.suggestionText}>{suggestion.text}</p>
                                    <div
                                        className={styles.animatedBorder}
                                        style={{borderColor: borderColor}}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SuggestionBoard;
