import React, {useRef} from "react";
import {Link, useLocation} from "react-router-dom";
import AuthenticationService from "../../../../../../api/authentication/AuthenticationService";
import { NavLink } from "react-router-dom";
import styles from "../../../../../../css/Navbar.module.css";
import {Button} from "react-bootstrap";

const NavLinks = () => {
  const userLogged = AuthenticationService.isUserLoggedIn();
  const businessLogged = AuthenticationService.isBusinessLoggedIn();
  const location = useLocation();

  const scrollToSection = (section) => {
    const element = document.querySelector(section)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {userLogged && (
          <ul className={styles.nav_links}>
            <li className={styles.nav_link}>
            </li>
            <li className={styles.nav_link}>
{/*               <ImageMenu/> */}
            </li>

          </ul>
      )}

      {businessLogged && (
          <ul className={styles.nav_links}>
            {/*<li className={styles.nav_link}>
              <NavLink to="/business-home">Home</NavLink>
            </li>
            <li
                className={styles.nav_link}
                onClick={AuthenticationService.logout}
            >
              <NavLink to="/"> Logout</NavLink>
            </li>*/}



          </ul>
      )}

      {!userLogged && !businessLogged && (
          <ul className={styles.nav_links}>
            <li className={styles.navbar}>
              <NavLink to="#Hero" onClick={() => scrollToSection('#Hero')}>Home</NavLink>
            </li>
            <li className={styles.navbar}>
              <NavLink to="#Features" onClick={() => scrollToSection('#Feature')}>Features</NavLink>
            </li>
            {/*<li className={styles.navbar}>
              <NavLink to="#Contact" onClick={() => scrollToSection("#Contact")}>Contact</NavLink>
            </li>*/}
            <li className={styles.navbar}>
              <NavLink to="/aboutus">About Us</NavLink>
            </li>
            {/*<li className={styles.navbar}>
              <NavLink to="/blog">Blog</NavLink>
            </li>*/}

            {location.pathname !== "/login" && (
                <li className={styles.nav_link}>
                  <Button variant="primary" className="text-white" size="md" href="/login">Login</Button>
                </li>
            )}
          </ul>
      )}
    </>
  );
};

export default NavLinks;
