/* .offer_body */
.offer_main {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  margin: auto;
  background: linear-gradient(to right, #696989, #dcdce6);
  color: white;
  width: 800px;
  min-height: 500px;
  height: min-content;
  border-radius: 15px;
  padding: 10%;
  box-shadow: 10px 10px 12px 12px rgba(248, 246, 246, 0.75);
  margin-bottom: 50px;
}

/* .create_offer{
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    font-family: sans-serif;
  } */

.title_offer {
  color: white;
  margin-bottom: 50px;
  margin: auto;
}

.form_offer {
  width: 100%;
}

.form_field_photos {
  display: flex;
  margin-top: 30px;
}

.row_upload {
  padding-top: 10px;
}

.form_field input:focus + .label_name .content_name,
.form_field input:valid + .lable_name .content_name {
  transform: translateY(-150%);
  font-size: 14px;
  color: #5fa8d3;
}

.form_field input:focus + .label_name::after,
.form_field input:valid + .label_name::after {
  transform: translateX(0%);
}

.button3 {
  margin-top: 15px;
  border: 1px solid white;
  border-radius: 15%;
  width: 200px;
  overflow: hidden;
}

.choose_file {
  padding-left: 18%;
  position: relative;
  top: 10px;
}

.errors_offer {
  font-size: 14px;
  color: #c24564;
  margin-left: 5px;
  margin-top: 10px;
  float: right;
}

.form_field_2 {
  margin: 20px 0 20px 0;
  padding: 0px;
  width: 100%;
  height: 100%;
}

.form_field_2 .h_n {
  background-color: white;
  padding: 20px 20px 20px 20px;
  content: " ";
  background-color: #fff;
  display: inline-block;
  color: #595f6e;
  width: 100%;
  visibility: visible;
  border: 1px solid white;
  margin-top: 20px;
  border-radius: 5px;
}

.custom_select select {
  display: none;
}
.custom_select option {
  margin-right: 10px;
}
.custom_select {
  padding: 5px;
  font-size: 16px;
  height: 34px;
  -webkit-appearance: none;
  background-position: bottom 15px right 20px;
  content: " ";
  background-color: #fff;
  display: inline-block;
  padding: 15px;
  color: #595f6e;
  height: 60px;
  visibility: visible;
  border: 1px solid white;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  padding-right: 10%;
}

.custom_select select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  background-color: #2f922f;
}

.submit_offer {
  padding: 10px 10px 10px 10px;
  content: " ";
  font-size: 1em;
  background-color: transparent;
  display: inline-block;
  margin-left: 10px;
  color: white;
  width: 100px;
  height: 50px;
  visibility: visible;
  border: 1px solid white;
  padding-left: 3px;
  border-radius: 5px;
  margin-bottom: 60px;
  margin-top: 30px;
}

input[type="file"] {
  /* z-index: 99 !important;  */
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

#category {
  margin-top: 20px;
}

#category::after {
  padding-left: 10px;
}

.column {
  float: right;
  width: 80%;
}

.row_upload {
  padding-top: 10px;
}

textarea {
  font-family: "Poppins", sans-serif;
  background-color: #00bff0;
  padding: 20px 20px 20px 20px;
  content: " ";
  background-color: #fff;
  display: inline-block;
  color: #595f6e;
  width: 100%;
  height: 200px;
  visibility: visible;
  border: 1px solid white;
  margin-top: 20px;
  border-radius: 5px;
}

@media all and (max-width: 850px) {
  .offer_main {
    width: 90%;
  }
  .create_offer {
    padding-top: 100px;
    margin-top: 90px;
    position: absolute;
    width: 100%;
    top: 0;
    padding-top: 0;
    /* z-index: -1; */
  }
}
