import React from 'react';
import LeftPanel from './LeftPanel';
import SmallCard from './SmallCard';
import SmallCard1 from './SmallCard1'; //temporary
import styles from '../../../../css/ProfilePage.module.css';
import NotificationIcon from './Notification';
import EmotionDashboard from './EmotionDashboard';
import Leaderboard from './Leaderboard';
import SuggestionBoard from './SuggestionBoard'


const ProfilePage = () => {

  const bigFiveData = [
    { label: 'Openness', score: '85' },
    { label: 'Conscientiousness', score: '90' },
    { label: 'Extraversion', score: '25' },
    { label: 'Agreeableness', score: '50' },
    { label: 'Neuroticism', score: '20' }
  ];

  const traits = [{
    "Creative": "Enjoys exploring new ideas and concepts, often thinks outside the box",
    "Curious": "Has a strong desire to learn and experience new things.",
    "Imaginative": " Tends to have a rich imagination and enjoys engaging in abstract thinking.",
    "Open-Minded": "Receptive to different viewpoints and alternative lifestyles.",
    "Adventurous": "Willing to take risks and try new activities."
  },
    {
      "Dependable": " Reliable, follows through on commitments and responsibilities. ",
      "Organized": "Keeps things in order, plans ahead, and likes structure. ",
      "Disciplined": "Has strong self-control and can delay gratification to achieve long-term goals.",
      "Efficient": "Works diligently and effectively, often producing high-quality work.",
      "Persistent": "Stays focused on goals and persists even when faced with challenges."
    },
    {
    "Sociable": "Enjoys being around people, finds energy in social interactions.",
    "Assertive": "Confident in expressing opinions and leading others.",
    "Enthusiastic": "Displays high levels of energy and excitement, often the life of the party.",
    "Talkative": "Comfortable with verbal communication and enjoys engaging in conversations.",
    "Outgoing": "Seeks out social opportunities and enjoys being in the spotlight."
    },
    {
      "Compassionate": "Caring and empathetic, easily tuned into others’ feelings.",
      "Cooperative": "Works well with others, often prioritizing harmony in relationships.",
      "Trusting": "Gives people the benefit of the doubt, tends to see the good in others.",
      "Altruistic": "Willing to put others’ needs ahead of their own, enjoys helping others.",
      "Generous": "Often willing to share time, resources, and support with others."
    },
    {
     "Anxious": "Frequently experiences worry or nervousness, tends to be easily stressed.",
     "Emotionally Reactive": "Has strong emotional responses to events, both positive and negative.",
     "Moody": "May experience frequent changes in mood, often feeling overwhelmed.",
     "Self-Conscious": "May be overly concerned with what others think, leading to feelings of inadequacy.",
     "Vulnerable": "Feels easily threatened or susceptible to stress and emotional instability."
    }
  ];

const tactics = {
  "Feedback Sandwich": {
    score: 7,
    tooltip: "Give constructive criticism by sandwiching it between positive comments."
  },
  "Groupthink Avoidance": {
    score: 9,
    tooltip: "Encourage diversity of thought and challenge consensus to prevent poor decision-making."
  },
  "Decoy Effect": {
    score: 6,
    tooltip: "Introduce a less attractive option to make another choice seem more appealing."
  },
  "Priming": {
    score: 8,
    tooltip: "Use subtle cues or triggers to influence thoughts and behaviors in a specific direction."
  },
  "Scarcity": {
    score: 10,
    tooltip: "Highlight the uniqueness or limited availability of resources or opportunities to increase their perceived value."
  },
  "Contrast Principle": {
    score: 5,
    tooltip: "Place options side by side to highlight differences."
  },
  "Mirroring": {
    score: 4,
    tooltip: "Mimic behavior or speech patterns to build rapport."
  },
  "Yes, But": {
    score: 3,
    tooltip: "Agreeing with the other person’s point of view first, then gently introducing your own perspective."
  },
  "Reverse Psychology": {
    score: 2,
    tooltip: "Suggest the opposite of what you want to provoke the desired action."
  },
  "Humility Check": {
    score: 1,
    tooltip: "Acknowledge your own limitations or mistakes before providing feedback."
  }
};


  const sampleEmotions = [
    { label: 'Happiness', severity: 'High', score: '85' },
    { label: 'Sadness', severity: 'Medium', score: '60' },
    { label: 'Anger', severity: 'Low', score: '45' },
    { label: 'Fear', severity: 'High', score: '75' },
    { label: 'Surprise', severity: 'Medium', score: '65' },
    { label: 'Disgust', severity: 'Low', score: '40' },
    { label: 'Joy', severity: 'High', score: '90' },
    { label: 'Trust', severity: 'Medium', score: '70' }
  ];


  return (
      <div className="main-content-div">
        <div className={styles.profilePage}>
          <div className={styles.profileContent}>
            <div className={styles.cardContainer}>
              <div className={styles.smallCards}>
                <div className="row">
                  {bigFiveData.map((data, index) => (
                      <div className="col">
                        <SmallCard1 key={index} label={data.label} score={data.score} colorIndex={index} traits={traits[index]}/>
                      </div>
                  ))}
                </div>
              </div>
            </div>
            <EmotionDashboard emotions={sampleEmotions} tactics={tactics}/>
            <SuggestionBoard/>
          </div>
        </div>
      </div>
        );
        };

        export default ProfilePage;
