import React from "react";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import styles from "../../../../css/DosAndDonts.module.css";

const dos = [
  "Be proactive and take initiative.",
  "Communicate clearly and effectively.",
  "Respect others' time and opinions.",
  "Maintain a positive attitude.",
  "Follow best practices and guidelines."
];

const donts = [
  "Procrastinate or delay important tasks.",
  "Ignore feedback or constructive criticism.",
  "Be disrespectful or dismissive of others.",
  "Spread negativity or complain excessively.",
  "Violate rules or guidelines."
];

const DosAndDonts = () => {
  return (
      <div className={styles.container}>
        <div className="row">
          <div className="col-md-6">
            <div className={`${styles.card} ${styles.doCard}`}>
              <h2 className={`${styles.cardTitle} ${styles.doCardTitle}`}>Do's</h2>
              <ul className={styles.list}>
                {dos.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                      <FcCheckmark/> {item}
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`${styles.card} ${styles.dontCard}`}>
              <h2 className={`${styles.cardTitle} ${styles.dontCardTitle} `}>Don'ts</h2>
              <ul className={styles.list}>
                {donts.map((item, index) => (
                    <li key={index} className={styles.listItem}>
                      <FcCancel/> {item}
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
};

      export default DosAndDonts;
