import React from 'react';
import styles from '../../../../css/PageNotFound.module.css';  // Optional: Add your own styling

const PageNotFound = () => {
  return (
    <div className={styles.pageNotFound}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default PageNotFound;
