/* ProfileCard.module.css */

.card {
    position: absolute;
    top: 60px; /* Adjust based on your layout */
    right: 20px; /* Adjust based on your layout */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: 100px;
    z-index: 1000; /* Ensure it appears above other content */
}

.options {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    margin-top: 10px;
}

.logoutButton {
    background-color: #7986cb; /* Purple color */
    border-radius: 5px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
}

.logoutLink {
    color: #fff; /* White text */
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
}

.logoutButton:hover {
    background-color: #4b0082; /* Darker purple on hover */
}
