.chip {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  background-color: #f1f1f1;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.chip_text {
  margin-right: 5px;
}

.chip_circle_text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5864ff;
  color: white;
  border-radius: 20px;
  font-size: 0.8rem;
  margin-left: 5px;
  padding: 2px 6px;
}

.chip_tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -50px;
  z-index: 1;
  width: 100px;
}

.chip:hover .chip_tooltip {
  visibility: visible;
  opacity: 0.8;
  transition: opacity 0.5s;
}
