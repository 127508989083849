import React from "react";
import styles from "../../../../css/ChipList.module.css";

const ChipList = ({
  children,
  orientation = "horizontal",
  singleLine = false,
}) => {
  // main style
  const classNames = [styles.chip_list];

  // orientation vertical or horizontal
  orientation === "horizontal"
    ? classNames.push(styles.horizontal)
    : classNames.push(styles.vertical);

  // single line
  if (singleLine) classNames.push(styles.single_line);

  return <div className={classNames.join(" ")}>{children}</div>;
};

export default ChipList;
