/* setting.css */

.settingsContainer {
  align-items: center;
  padding: 20px;
}

.header {
  font-size: 24px;
  color: #7986cb; /* Light Blue */
  margin-bottom: 20px;
}

.connectors {
  justify-content: center;
  flex-wrap: wrap;
}

.connectorCard {
  background-color: white; /* Light Grey */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 250px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.connectorCard:hover {
  background-color: #f0f0f0; /* Slightly darker grey */
}

.connectorIcon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.connectorTitle {
  font-size: 18px;
  color: #4db6ac; /* Teal */
  margin-bottom: 10px;
}

.connectorDescription {
  font-size: 14px;
  color: #494969; /* Dark Grey */
  margin-bottom: 20px;
}

.connectedButton {
  background-color: #ffffff; /* Coral */
  color: #81c784;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  font-weight: bold;

}

.connectButton {
  background-color: #7986cb; /* Coral */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.connectButton:hover {
  background-color: #e55d50; /* Darker Coral */
}
