@import url('./PsygenieCore.css');

.blueImg {
  position: absolute;
  bottom: 0px;
  height: 60%;
  color: #494969;
  z-index: -2;
  opacity: 0.1;
  width: 70%;
}

.blueImg2 {
  background-size: cover;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  height: 100%;
  color: #494969;
  z-index: -1;
  opacity: 0.11;
  width: 100%;
}

.creative {
  position: absolute;
  width: 50%;
  height: 100%;
  top: -330px;
  right: -200px;
  bottom: 0px;
  height: 100%;
  color: #494969;
  z-index: -1;
  opacity: 0.4;
}

.blueImg4 {
  position: absolute;
  left: 0px;
  height: 60%;
  bottom: 0px;
  height: 100%;
  top: 0;
  color: #494969;
  z-index: -1;
  opacity: 0.11;
  width: 100%;
}

.login_page {
  background-color: var(--background-main);
  margin: 0px;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.login_page main {
  background: var(--background-content);
  padding: 0px;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 15px rgba(0, 0, 0, 0.4);
  min-width: 60%;
  max-width: 60%;
  display: inline-flex;
}

.signup_page {
  background-color: var(--background-main);
  margin: 0px;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.signup_page main {
  background: white;
  padding: 0px;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 15px rgba(0, 0, 0, 0.4);
  min-width: 60%;
  display: inline-flex;
  max-width: 60%;
}


.signup_page main {
  background: white;
  padding: 0px;
  border-radius: 30px;
  box-shadow: 0px 10px 15px 15px rgba(0, 0, 0, 0.4);
  min-width: 60%;
  max-width: 60%;
  align-items: center;
}

@media all and (min-width: 800px) {
  .blueImg3 {
    display: none;
  }
  .blue {
    display: none;
  }
}

@media all and (max-width: 800px) {
  .blueImg2 {
    position: absolute;
    height: 60%;
    top: 8%;
    bottom: 8%;
  }

  .blueImg3 {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 60vh;
    color: #494969;
    z-index: -1;
    opacity: 0.2;
    width: 30%;
  }

  .blueImg4 {
    width: 100%;
  }

  .blue {
    top: 100%;
    left: 0;
    opacity: 0.2;
    z-index: -1;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 60%;
    width: 50%;
  }
}
