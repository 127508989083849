import React from "react";
import BackgroundCover from "./BackgroundCover";
import Presentation from "./Presentation";
/*import Footer from "../fragments/footer/Footer";*/
import style from "../../../css/Footer.module.css";

import Cover from "./Cover";
import HeroSection from "./landing/HeroSection";
import FeaturesSection from "./landing/FeaturesSection";
import TestimonialsSection from "./landing/TestimonialsSection";
import ContactSection from "./landing/ContactSection";
import Footer from "./landing/Footer";
import SubscribeSection from "./landing/SubscribeSection";
import PersonalInsights from "./landing/PeronalInsight";
import PersonalityNavigator from "./landing/PersonalityNavigator";
import InstantInsight from "./landing/InstantInsight";


const Home = () => {
  return (
      <>
          <div>
              <HeroSection/>
              {/*<FeaturesSection/>*/}

              <PersonalInsights/>
              <PersonalityNavigator/>
              <InstantInsight/>
              {/*<TestimonialsSection/>*/}
              {/*<ContactSection/>*/}
              <SubscribeSection/>
              <Footer/>
          </div>
      </>
  );
};

export default Home;
