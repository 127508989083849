.leaderboardContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.cardContainer {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;
}

.leaderboardCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  background: linear-gradient(to right, #ffffff, #f0f0f0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 200px;
  height: 200px;
}

.leaderboardCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.avatarContainer {
  flex-shrink: 0;
  margin-right: 15px;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.details {
  text-align: center;
}

.rank {
  font-size: 22px;
  font-weight: bold;
  color: #6200ea;
  margin-bottom: 5px;
}

.name {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.score {
  font-size: 16px;
  color: #666;
}
