import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../css/Smallcard1.module.css';
import ExpandedModal from './ExpandedModal'; // Import the ExpandedModal component
import { AiFillCloseCircle } from "react-icons/ai";

const colorClasses = ['color1', 'color2', 'color3', 'color4', 'color5'];

const SmallCard1 = ({ label = 'Default Label', score = 0, colorIndex = 0, traits = {} }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const [animatedScore, setAnimatedScore] = useState(0);
  const [offset, setOffset] = useState(circumference);
  const [hoveredTrait, setHoveredTrait] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [traitKey, setTraitKey] = useState(null); // State to manage the current trait key

  useEffect(() => {
    const animationDuration = 2000;
    const increment = score / (animationDuration / 10);
    let currentScore = 0;

    const animate = () => {
      currentScore += increment;
      if (currentScore >= score) {
        currentScore = score;
        clearInterval(intervalId);
      }
      setAnimatedScore(Math.floor(currentScore));
      setOffset(circumference - (currentScore / 100) * circumference);
    };

    const intervalId = setInterval(animate, 10);

    return () => clearInterval(intervalId);
  }, [score, circumference]);

  const handleCardClick = () => {
    setIsModalOpen(true);
    setTraitKey('Openness'); // Set the trait key based on your logic or requirements
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setExpandedRow(null); // Reset expandedRow when closing modal
    setTraitKey(null); // Reset traitKey when closing modal
  };

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  return (
    <div className={styles.parentDiv}>
      <div className={styles.smallCard} onClick={handleCardClick}>
        <div className={styles.label}>{label}</div>
        <div className={styles.ringContainer}>
          <svg width="80" height="80">
            <circle
              className={styles.ringBackground}
              cx="40"
              cy="40"
              r={radius}
              strokeWidth="10"
            />
            <circle
              className={`${styles.ringProgress} ${styles[colorClasses[colorIndex]]}`}
              cx="40"
              cy="40"
              r={radius}
              strokeWidth="10"
              strokeDasharray={circumference}
              strokeDashoffset={offset}
            />
            <text
              className={styles.ringText}
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              {`${animatedScore}%`}
            </text>
          </svg>
        </div>
        <div className={styles.traitsContainer}>
          {Object.keys(traits).map((key) => (
            <div
              key={key}
              className={`${styles.traitTag} ${styles[colorClasses[colorIndex]]}`}
              onMouseEnter={() => setHoveredTrait(key)}
              onMouseLeave={() => setHoveredTrait(null)}
            >
              {key}
              {hoveredTrait === key && (
                <div className={`${styles.content} ${styles[colorClasses[colorIndex]]}`}>
                  {traits[key]}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Render the modal when isModalOpen is true */}
      {isModalOpen && traitKey && (
        <div className={styles.modalBackdrop} onClick={handleCloseModal}>
          <div
            className={styles.modalOverlay}
            onClick={(e) => e.stopPropagation()} // Prevent click from closing modal
          >
            <ExpandedModal
              traitKey={label} // Pass the traitKey here
              expandedRow={expandedRow}
              onRowClick={handleRowClick}
            />
            <button className={styles.closeButton} onClick={handleCloseModal}>
              <AiFillCloseCircle />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

SmallCard1.propTypes = {
  label: PropTypes.string,
  score: PropTypes.number.isRequired,
  colorIndex: PropTypes.number,
  traits: PropTypes.objectOf(PropTypes.string),
};

export default SmallCard1;
