import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = () => {
  const handleSuccess = (response) => {
    console.log('Google Sign-In Successful:', response);
    // Handle successful login response here
  };

  const handleError = (error) => {
    console.error('Google Sign-In Error:', error);
    // Handle error here
  };

  return (
      <GoogleOAuthProvider clientId="YOUR_CLIENT_ID">
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            text="Login with Google"
            buttonText="Login with Google"
            className="google-login-button"
            size="large"
        />
      </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;