.profilePage {
  display: flex;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.leftPanel {
  width: 250px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
}

.profileContent {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
  position: relative;
}

.topRightSection {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #6200ea;
}

.bellIcon {
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.cardsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.hrWithShadow {
  border: none;
  height: 2px;
  background-color: #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
}

.cardContainer {
}

.smallCards {

}

.topSection {
  display: flex;
  justify-content: space-between; /* Adjusts the spacing between items */
  align-items: flex-start; /* Aligns items at the top of the container */
  margin-bottom: 20px; /* Adds space below the top section */
}

.topSection > * {
  flex: 1;
  margin-right: 10px; /* Optional: Add space between UserView and TopMessages */
}

/* Reset the last child margin */
.topSection > *:last-child {
  margin-right: 0;
}

.alignTopMessages{
margin-top:30px;
}

