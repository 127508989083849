import React from "react";
import styles from "../../../../css/AboutUs.module.css";
import umangimg from "../../../../img/umang-team.png";
import ppred from "../../../../img/pp-red.png";
import ppyellow from "../../../../img/pp-yellow.png";
import pppink from "../../../../img/pp-pink.png";

const AboutUs = () => {
  return (
    <div className="main-content-div">
      <div className={styles.aboutContainer}>
        <section className={styles.hero}>
          <p>
            We are dedicated to providing top-notch solutions and creating
            impactful experiences.
          </p>
        </section>

        <section className={styles.mission}>
          <h2>Our Mission</h2>
          <p>
            Our mission is to empower individuals and businesses by providing
            innovative solutions that drive success. We strive to create a
            positive impact through our products and services.
          </p>
        </section>

        <section className={styles.values}>
          <h2>Our Values</h2>
          <ul className={styles.valuesList}>
            <li>Innovation: Continuously improving and innovating.</li>
            <li>
              Integrity: Maintaining honesty and transparency in all our
              dealings.
            </li>
            <li>
              Customer Focus: Prioritizing customer satisfaction above all.
            </li>
            <li>
              Excellence: Striving for the highest standards in everything we
              do.
            </li>
          </ul>
        </section>

        <section className={styles.team}>
          <h2>Meet the Team</h2>
          <div className={styles.teamGrid}>
            <div className={styles.teamMember}>
              <img
                src={umangimg}
                alt="Team Member 1"
                className={styles.teamPhoto}
              />
              <h3>Umang Parekh</h3>
              <h6>Founder</h6>
              <p>14 years of experience</p>
            </div>
           </div>
        </section>

        <section className={styles.contact}>
          <h2>Get in Touch</h2>
          <p>
            We’d love to hear from you! Reach out to us via email at{" "}
            <a href="mailto:info@psygenie.ai">info@psygenie.ai</a>
            
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
