import React from 'react';
import styles from '../../../../css/ComingSoon.module.css';

function ComingSoon() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Coming Soon</h1>
        <p className={styles.subtitle}>
          We're working hard to bring you something amazing. Stay tuned!
        </p>
        <div className={styles.loader}>
          <div className={styles.dot} style={{ backgroundColor: '#ff6f61' }}></div>
          <div className={styles.dot} style={{ backgroundColor: '#4db6ac' }}></div>
          <div className={styles.dot} style={{ backgroundColor: '#ffb74d' }}></div>
          <div className={styles.dot} style={{ backgroundColor: '#7986cb' }}></div>
          <div className={styles.dot} style={{ backgroundColor: '#81c784' }}></div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
