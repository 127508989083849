.sidebar {
    min-width: 200px;
    display: flex;
    position: fixed;
    z-index: 10000;
    height: 100vh;
    left: 0px;
    top: 0px;
    background-color: #0e063b;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.sidebar_panel {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #b8c7ce;
}

.sidebar_panel ul li a {
    color: #b8c7ce;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}


.sidebar_panel ul li a:hover {
    background: #6257a5;
    color: white;
    border-left: 4px solid white !important;
}

.sidebar_content {
    min-width: 200px;
}

.sidebar_menu_logo {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    font-size: 22px;
    align-items: center;
    padding-left: 15px;
}

.sidebar_menu_logo span{
 padding-bottom:5px;
}

.sidebar_menu_navigation {
    background: #322969;
    padding: 5px;
    padding-left: 15px;
    color: lightslategrey;
    font-size: 14px;
}

.sidebar_panel_footer {
    position: absolute;
    bottom: 10px;
    font-size: 10px;
    margin-left: 10px;
    color: darkgray;
}
