import React from 'react';
import styles from '../../../../css/SuggestionModal.module.css';
import PropTypes from 'prop-types';
import { AiFillCloseCircle } from "react-icons/ai";

const SuggestionModal = ({ suggestion, closeModal }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{suggestion.text}</h2>
        <p className={styles.modalDescription}>{suggestion.description}</p>
        <h3 className={styles.examplesTitle}>Examples:</h3>
        <ul className={styles.examplesList}>
          {suggestion.examples.map((example, index) => (
            <li key={index} className={styles.exampleItem}>{example}</li>
          ))}
        </ul>
        <button className={styles.closeButton} onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
};

SuggestionModal.propTypes = {
  suggestion: PropTypes.shape({
    text: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    examples: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SuggestionModal;
