.gallery {
  margin: 3% 3% 3% auto;
  height: 100%;
  padding-left: 10%;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.column {
  margin: auto 15px;
  -ms-flex: 25%;
  flex: 25%;
  max-width: 40%;
}

.img {
  transition: transform 0.2s;
  margin-top: 15px;
  vertical-align: middle;
  width: 100%;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.img:hover {
  padding: 5%;
  box-shadow: -2px 0px 3px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 50%;
  height: auto;
  object-fit: cover;
  top: 5%;
  right: 5%;
  bottom: 0;
  left: 0;
  margin: auto;
  transform: scale(1.5);
}

@media all and (max-width: 1400px) {
  .column {
    max-width: 100%;
    margin: 0;
  }
  .img {
    margin-bottom: 10px;
    padding: 3%;
  }
}

@media all and (max-width: 600px) {
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
  .img {
    pointer-events: none;
  }

  .gallery {
    padding-left: 15px;
  }
}
