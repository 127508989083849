.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  max-height: 80vh; /* Maximum height to fit within the viewport */
  padding: 20px;
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
  overflow-y: auto; /* Makes the content scrollable if it exceeds max-height */
}

.modalTitle {
  margin-top: 0;
  font-size: 1.5em;
  color: #333;
}

.modalDescription {
  font-size: 1em;
  color: #555;
}

.examplesTitle {
  font-size: 1.2em;
  color: #333;
  margin-top: 10px;
}

.examplesList {
  list-style-type: disc;
  margin: 10px 0;
  padding-left: 20px;
}

.exampleItem {
  margin-bottom: 5px;
  color: #666;
}

.closeButton {
  background: #4db6ac;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px; /* Adds space between content and button */
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: block;
  width: 100%; /* Makes the button full-width */
  text-align: center;
}

.closeButton:hover {
  background: #ffb74d;
  transform: scale(1.05);
}
