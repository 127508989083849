const friends = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@example.com",
    profilePic:
      "https://hips.hearstapps.com/hmg-prod/images/apple-ceo-steve-jobs-speaks-during-an-apple-special-event-news-photo-1683661736.jpg?crop=1xw:0.52889xh;center,top&resize=1200:*",
    score: 85,
    color: "#ff6f61",
    personality: [
      { trait: "O", percentage: 70 },
      { trait: "C", percentage: 60 },
      { trait: "E", percentage: 80 },
      { trait: "A", percentage: 75 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Content", percentage: 70 },
      { emotion: "Anxious", percentage: 10 },
      { emotion: "Fearful", percentage: 5 },
      { emotion: "Joyful", percentage: 10 },
      { emotion: "Relieved", percentage: 5 },
    ],
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "janesmith@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/62d599ede3ff49f348f9b9b4/0x0.jpg?format=jpg&crop=821,821,x155,y340,safe&height=416&width=416&fit=bounds",
    score: 90,
    color: "#4db6ac",
    personality: [
      { trait: "O", percentage: 85 },
      { trait: "C", percentage: 65 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 80 },
      { trait: "N", percentage: 30 },
    ],
    emotions: [
      { emotion: "Optimistic", percentage: 60 },
      { emotion: "Disappointed", percentage: 15 },
      { emotion: "Frustrated", percentage: 10 },
      { emotion: "Curious", percentage: 10 },
      { emotion: "Confident", percentage: 5 },
    ],
  },
  {
    id: 3,
    name: "Alice Johnson",
    email: "alicejohnson@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/5d6ae14f673aa300083caf99/0x0.jpg?format=jpg&crop=2923,2926,x3051,y26,safe&height=416&width=416&fit=bounds",
    score: 88,
    color: "#ffb74d",
    personality: [
      { trait: "O", percentage: 75 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 85 },
      { trait: "A", percentage: 65 },
      { trait: "N", percentage: 45 },
    ],
    emotions: [
      { emotion: "Calm", percentage: 65 },
      { emotion: "Worried", percentage: 10 },
      { emotion: "Confused", percentage: 8 },
      { emotion: "Energized", percentage: 12 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 4,
    name: "Bob Brown",
    email: "bobbrown@example.com",
    profilePic:
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    score: 82,
    color: "#7986cb",
    personality: [
      { trait: "O", percentage: 60 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 65 },
      { trait: "A", percentage: 70 },
      { trait: "N", percentage: 50 },
    ],
    emotions: [
      { emotion: "Hopeful", percentage: 55 },
      { emotion: "Bored", percentage: 20 },
      { emotion: "Annoyed", percentage: 5 },
      { emotion: "Motivated", percentage: 15 },
      { emotion: "Startled", percentage: 5 },
    ],
  },
  {
    id: 5,
    name: "Charlie Davis",
    email: "charliedavis@example.com",
    profilePic:
        "https://www.shutterstock.com/image-photo/head-shot-portrait-close-smiling-600nw-1714666150.jpg",
    score: 79,
    color: "#81c784",
    personality: [
      { trait: "O", percentage: 80 },
      { trait: "C", percentage: 90 },
      { trait: "E", percentage: 20 },
      { trait: "A", percentage: 50 },
      { trait: "N", percentage: 10 },
    ],
    emotions: [
      { emotion: "Sadness", percentage: 50 },
      { emotion: "Upset", percentage: 10 },
    ],
  },
  {
    id: 6,
    name: "Diana Evans",
    email: "dianaevans@example.com",
    profilePic:
      "https://miro.medium.com/v2/resize:fit:1400/0*0fClPmIScV5pTLoE.jpg",
    score: 84,
    color: "#ff6f61",
    personality: [
      { trait: "O", percentage: 70 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 78 },
      { trait: "A", percentage: 72 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Grateful", percentage: 65 },
      { emotion: "Lonely", percentage: 10 },
      { emotion: "Angry", percentage: 8 },
      { emotion: "Thrilled", percentage: 12 },
      { emotion: "Startled", percentage: 5 },
    ],
  },
  {
    id: 7,
    name: "Eve Fisher",
    email: "evefisher@example.com",
    profilePic:
      "https://newprofilepic.photo-cdn.net//assets/images/article/profile.jpg?90af0c8",
    score: 81,
    color: "#4db6ac",
    personality: [
      { trait: "O", percentage: 72 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 68 },
      { trait: "N", percentage: 45 },
    ],
    emotions: [
      { emotion: "Satisfied", percentage: 60 },
      { emotion: "Discouraged", percentage: 12 },
      { emotion: "Annoyed", percentage: 5 },
      { emotion: "Amused", percentage: 15 },
      { emotion: "Impressed", percentage: 8 },
    ],
  },
  {
    id: 8,
    name: "Frank Green",
    email: "frankgreen@example.com",
    profilePic:
      "https://hips.hearstapps.com/hmg-prod/images/apple-ceo-steve-jobs-speaks-during-an-apple-special-event-news-photo-1683661736.jpg?crop=1xw:0.52889xh;center,top&resize=1200:*",
    score: 77,
    color: "#ffb74d",
    personality: [
      { trait: "O", percentage: 68 },
      { trait: "C", percentage: 65 },
      { trait: "E", percentage: 70 },
      { trait: "A", percentage: 75 },
      { trait: "N", percentage: 50 },
    ],
    emotions: [
      { emotion: "Relieved", percentage: 55 },
      { emotion: "Jealous", percentage: 20 },
      { emotion: "Offended", percentage: 10 },
      { emotion: "Relaxed", percentage: 10 },
      { emotion: "Delighted", percentage: 5 },
    ],
  },
  {
    id: 9,
    name: "Grace Hill",
    email: "gracehill@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/62d599ede3ff49f348f9b9b4/0x0.jpg?format=jpg&crop=821,821,x155,y340,safe&height=416&width=416&fit=bounds",
    score: 89,
    color: "#7986cb",
    personality: [
      { trait: "O", percentage: 75 },
      { trait: "C", percentage: 70 },
      { trait: "E", percentage: 85 },
      { trait: "A", percentage: 70 },
      { trait: "N", percentage: 35 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 70 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 5 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 10,
    name: "Henry Isaac",
    email: "henryisaac@example.com",
    profilePic:
      "https://imageio.forbes.com/specials-images/imageserve/5d6ae14f673aa300083caf99/0x0.jpg?format=jpg&crop=2923,2926,x3051,y26,safe&height=416&width=416&fit=bounds",
    score: 86,
    color: "#81c784",
    personality: [
      { trait: "O", percentage: 70 },
      { trait: "C", percentage: 80 },
      { trait: "E", percentage: 75 },
      { trait: "A", percentage: 65 },
      { trait: "N", percentage: 40 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 65 },
      { emotion: "Sad", percentage: 10 },
      { emotion: "Angry", percentage: 10 },
      { emotion: "Excited", percentage: 10 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
  {
    id: 11,
    name: "Ivy James",
    email: "ivyjames@example.com",
    profilePic:
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    score: 83,
    color: "#ff6f61",
    personality: [
      { trait: "O", percentage: 65 },
      { trait: "C", percentage: 75 },
      { trait: "E", percentage: 70 },
      { trait: "A", percentage: 72 },
      { trait: "N", percentage: 55 },
    ],
    emotions: [
      { emotion: "Happy", percentage: 60 },
      { emotion: "Sad", percentage: 15 },
      { emotion: "Angry", percentage: 8 },
      { emotion: "Excited", percentage: 12 },
      { emotion: "Surprised", percentage: 5 },
    ],
  },
];

export default friends;
