import React, { createContext, useState, useContext, useEffect } from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    console.log('MenuProvider mounted');
    return () => {
      console.log('MenuProvider unmounted');
    };
  }, []);

  return (
    <MenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
