import React, { useState } from 'react';
import styles from '../../../../css/Tactics.module.css'; // Ensure the correct path
import TopMessagesModal from './TopMessagesModal'; // Import the correct component

const colors = ['#ff6f61', '#4db6ac', '#ffb74d', '#7986cb', '#81c784'];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

const Tactics = ({ tactics }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTactic, setSelectedTactic] = useState(null);

  const openModal = (tactic) => {
    setSelectedTactic(tactic);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTactic(null);
  };

  return (
    <div className={styles.tacticsContainer}>
      <table className={styles.tacticsTable}>
        <tbody>
          {Object.entries(tactics).map(([key, tactic]) => (
            <tr
              key={key}
              className={styles.tableRow}
              style={{ boxShadow: `1px 2px 4px ${getRandomColor()}` }}
              onClick={() => openModal(tactic)} // Open modal on row click
            >
              <td>{key}</td>
              <td>{tactic.score}</td>
              <div className={styles.tooltip}>
                {tactic.tooltip}
              </div>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedTactic && (
        <TopMessagesModal
          isOpen={isModalOpen}
          onClose={closeModal}
          emotion={selectedTactic.emotion}
          messages={selectedTactic.messages} // Pass the messages array
        />
      )}
    </div>
  );
};

export default Tactics;
