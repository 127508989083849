import React, { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { subscribe } from "../../../../api/chatbot/PsyGenieChatBot";

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleSubscribe = async (event) => {
    event.preventDefault();

    if (!recaptchaValue) {
      alert("Please complete the CAPTCHA");
      return;
    }

    try {
      const response = await subscribe(email, recaptchaValue);
      if (response.status === 200) {
        alert("Succesfully subscribed!");
      } else {
        alert("Failed to subscribe.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <section id="subscribe" className="subscribe py-5 mt-5">
      <div className="subscribe-container container">
        <div className="row">
          <div className="col-md-5">
            <h1 className="text-center pt-5 display-5">Subscribe Now</h1>
            <p className="text-center lead">
              Subscribe Now: Get Early Access and Updates on Our Product Launch.
            </p>
          </div>
          <div className="col-md-7">
            <Form className="p-5" onSubmit={handleSubscribe}>
              <Form.Group className="mb-4" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <ReCAPTCHA
                sitekey="6Lfy0TIqAAAAAMxeDpjkKpR2NGrpNjp5EBzprqrG"
                onChange={(value) => setRecaptchaValue(value)}
              />
              <Button
                type="submit"
                className="btn btn-outline-white btn-lg float-end mb-5"
                data-wow-delay=".6s"
              >
                Subscribe Now
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscribeSection;
