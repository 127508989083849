.aboutContainer {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  text-align: center;
  background-color: #7986cb;
  color: #fff;
  padding: 50px 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.hero h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.hero p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
}

.mission,
.values,
.team,
.contact {
  margin-bottom: 40px;
}

.mission h2,
.values h2,
.team h2,
.contact h2 {
  font-size: 28px;
  color: #494969;
  margin-bottom: 20px;
}

.mission p,
.contact p {
  font-size: 16px;
  line-height: 1.6;
}

.valuesList {
  list-style-type: none;
  padding: 0;
}

.valuesList li {
  background-color: #ffffff;
  border: 2px solid #7986cb;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.valuesList li:hover {
  background-color: #4db6ac;
  color: #ffffff;
}

.teamGrid {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.teamMember {
  text-align: center;
  max-width: 200px;
}

.teamPhoto {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.teamMember h3 {
  font-size: 20px;
  color: #494969;
  margin-bottom: 5px;
}

.teamMember p {
  font-size: 14px;
  color: #777;
}

.contact a {
  color: #4db6ac;
  text-decoration: none;
  transition: color 0.3s;
}

.contact a:hover {
  color: #388e7b;
}
