import React from 'react';
import style from '../../../../css/LeftPanel.module.css';
import logo from "./logo/logo.svg";

const LeftPanel = () => {
  return (
    <div className={style.leftPanel}>
      <div className={style.logo}>
        <img src={logo} alt="Logo" /> {/* Adjust path as necessary */}
        <h3> Psygenie </h3>
      </div>
      <ul className={style.menu}>
        <li className={`${style.menuItem} ${style.active}`}>
          <span className={style.menuIcon}>🏠</span>
          <span className={style.menuText}>Home</span>
        </li>
        <li className={style.menuItem}>
          <span className={style.menuIcon}>👤</span>
          <span className={style.menuText}>My Profile</span>
        </li>
        <li className={style.menuItem}>
          <span className={style.menuIcon}>🔗</span>
          <span className={style.menuText}>My Circle</span>
        </li>
        <li className={style.menuItem}>
          <span className={style.menuIcon}>ℹ️</span>
          <span className={style.menuText}>About Us</span>
        </li>
        <li className={style.menuItem}>
          <span className={style.menuIcon}>⚙️</span>
          <span className={style.menuText}>Settings</span>
        </li>
        <li className={style.menuItem}>
          <span className={style.menuIcon}>✉️</span>
          <span className={style.menuText}>Contact Us</span>
        </li>
      </ul>
    </div>
  );
};

export default LeftPanel;


